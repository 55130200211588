import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Upload, Button, ErrorNotification, SuccessNotification, Popconfirm } from 'components/common';
import { UploadOutlined, CheckOutlined } from '@ant-design/icons';
import FraudImage from 'assets/fraud_icon.png';
import { Wrapper } from './IdAndCreditVerificationPage.styled';
import { callPostApiWithAuth } from 'utils/api';

export default function IdAndCreditVerificationPage() {
  const history = useHistory();
  const [isUploading, setIsUploading] = useState(false);
  const [formData, setFormData] = useState({
    id_photo: undefined,
    cc_photo: undefined
  });

  const handleChangeImage = (field, file, fileList) => {
    if (!file) {
      ErrorNotification("Please select an image");
      return;
    }
    setFormData({
      ...formData,
      [field]: file
    });
  };

  const handleRemove = () => {
    setFormData({
      id_photo: undefined,
      cc_photo: undefined
    });
  };

  const onSuccessUploaded = () => {
    setIsUploading(false);
    SuccessNotification("ID and Credit Card has been uploaded successfully.");
    setTimeout(() => {
      history.push("/logout");
    }, 1500);
  };

  const onFailedUpload = (err) => {
    setIsUploading(false);
  }

  const handleSubmit = () => {
    if (!formData.id_photo || !formData.cc_photo) {
      ErrorNotification("Please upload images");
      return;
    }
    let requestData = new FormData();
    Object.keys(formData).map((key) => {
      requestData.append(key, formData[key]);
    });
    setIsUploading(true);
    callPostApiWithAuth("verification/upload", requestData, onSuccessUploaded, onFailedUpload);
  };

  return (
    <Wrapper>
      <div className="fraud-section">
        <div className="fraud-result-icon">
          <img src={FraudImage} />
        </div>
        <div className="fraud-result-message">
          <h5>There is a problem with your account.</h5>
          <p><strong>Note: </strong> Our Anti-Fraud system is to ensure that you are not a victim of credit card fraud, and its an extra layer of security to protect the company. It is completely safe to send in your Photo ID & Credit Card to have your transaction cleared. After submitting your documents, your transaction will be cleared within 24 hours.</p>
        </div>
        <div className="fraud-verify-section">
          <div className="fraud-verify-upload">
            <h5>Photo ID</h5>
            <label>Permitted</label>
            <ul>
              <li><CheckOutlined className="fraud-verify-permitted-icon" />Driver's License</li>
              <li><CheckOutlined className="fraud-verify-permitted-icon" />Passport</li>
              <li><CheckOutlined className="fraud-verify-permitted-icon" />Must Match Name On Account</li>
            </ul>
            <div className="fraud-verify-upload-btn">
              <Upload
                accept="image/*"
                maxCount={1}
                multiple={false}
                beforeUpload={(file, fileList) => handleChangeImage("id_photo", file, fileList)}
                onRemove={handleRemove}
              >
                <Button icon={<UploadOutlined />}>Upload Image</Button>
              </Upload>
            </div>
          </div>
          <div className="fraud-verify-upload">
            <h5>Picture of Credit Card</h5>
            <label>Permitted</label>
            <ul>
              <li><CheckOutlined className="fraud-verify-permitted-icon" />Front of Credit Card</li>
              <li><CheckOutlined className="fraud-verify-permitted-icon" />Back of Credit Card</li>
              <li><CheckOutlined className="fraud-verify-permitted-icon" />Must Be Credit Card Used For This Transaction</li>
            </ul>
            <div className="fraud-verify-upload-btn">
              <Upload
                accept="image/*"
                maxCount={1}
                multiple={false}
                beforeUpload={(file, fileList) => handleChangeImage("cc_photo", file, fileList)}
                onRemove={handleRemove}
              >
                <Button icon={<UploadOutlined />}>Upload Image</Button>
              </Upload>
            </div>
          </div>
        </div>
        <div className="fraud-verify-submit">
          <Popconfirm onConfirm={handleSubmit} disabled={isUploading}>
            <Button loading={isUploading} disabled={isUploading}>
              Submit
            </Button>
          </Popconfirm>
        </div>
      </div>
    </Wrapper>
  )
}