import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { 
  TablePanel, UserAvatar, CountryFlag, RankBadge,
  Select, Input, Button, Radio,  
} from 'components/common'
import {
  callGetApiWithAuth
} from 'utils/api'
import { asDate, asVolume } from 'utils/text'
import { varIs, varKey } from 'common/var'
import { BsChatDots } from 'react-icons/bs'
import { LoadingOutlined } from '@ant-design/icons'
import { MdOutlineComment } from 'react-icons/md'
import RightOutlinedIcon from '@ant-design/icons/RightOutlined'
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai'
import { FaFacebookMessenger, FaTelegramPlane, FaWhatsapp } from "react-icons/fa"

export default function TableView(props) {
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isExporting, setIsExporting] = useState(false)
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1, perPage: 15, total: 0
  })
  const [searchParam, setSearchParam] = useState({
    user_id: '',
    uuid: '', rank_id: '',
    status: '',
    level: '',
    order_by: '',
    order_dir: '',
    has_subscription: '',
  })
  const [columns, setColumns] = useState([])
  const [ranks, setRanks] = useState([])
  const [uplines, setUplines] = useState([])
  const [maxLevel, setMaxLevel] = useState(0)

  const onGetRanks = (res) => {
    setRanks(res.data)
  }

  const onGetMaxLevel = (res) => {
    setMaxLevel(res.data.max_level)
  }

  const onGetTableData = (res) => {
    setTableData(res.data.tableData.data)
    setPaginationParam({
      ...paginationParam,
      currentPage: res.data.tableData.current_page,
      total: res.data.tableData.total
    })
    setUplines(res.data.uplines)
    setIsLoading(false)
  }
  const onFailTableData = () => {
    setIsLoading(false)
  }
  const loadTableData = (paginationParam0, searchParam0) => {
    setIsLoading(true)
    setTableData([])
    const params = {
      ...searchParam0,
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    }
    const q = Object.keys(params).map(k=>k+'='+params[k]).join('&')
    callGetApiWithAuth(`contact/team_contact/list?${q}`, 
      onGetTableData, onFailTableData
    )
  }
  const searchBy = (userId) => {
    const searchParam0 = {
      ...searchParam,
      user_id: userId,
      uuid: '', rank_id: '',
    }
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1,
    }
    loadTableData(paginationParam0, searchParam0)
  }
  const handleOrderBy = (orderBy0) => {
    let searchParam0 = { ...searchParam }
    if (orderBy0 === searchParam0.order_by) {
      if (searchParam0.order_dir==='asc') {
        searchParam0.order_dir = 'desc';
      } else {
        searchParam0.order_dir = 'asc';
      }
    } else {
      searchParam0.order_dir = 'asc';
      searchParam0.order_by = orderBy0;
    }
    setSearchParam(searchParam0);
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1,
    }
    loadTableData(paginationParam0, searchParam0)
  }

  const handleSearch = () => {
    setTableData([])
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1,
    }
    loadTableData(paginationParam0, searchParam)
  }
  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page,
    }
    loadTableData(paginationParam0, searchParam)
  }

  const onExportDone = (res) => {
    setIsExporting(false)
    window.open(res.data.csv_file, '_blank')
  }
  const onExportFail = () => {
    setIsExporting(false)
  }
  const handleExport = () => {
    setIsExporting(true)
    const params = {
      ...searchParam,
    }
    const q = Object.keys(params).map(k=>k+'='+params[k]).join('&')
    callGetApiWithAuth(`contact/team_contact/export?${q}`, 
      onExportDone, onExportFail
    )
  }

  const onFormInput = (field, value) => {
    setSearchParam({
      ...searchParam,
      [field]: value
    })
  }

  useEffect(() => {
    if (props.shouldLoad) {
      handleSearch()
    }
  }, [props.shouldLoad])

  useEffect(() => {
    let searchParam0 = { 
      ...searchParam, 
      order_by: '',
      order_dir: ''
    }
    searchParam0.order_by = 'depth';
    searchParam0.order_dir = 'asc';
    setSearchParam(searchParam0);
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1,
    }
    loadTableData(paginationParam0, searchParam0)
  }, [searchParam.search_type])

  useEffect(() => {
    callGetApiWithAuth(`common/ranks`, onGetRanks)
    callGetApiWithAuth(`contact/team_contact/max_level`, onGetMaxLevel)
  }, [])

  return (
  <Wrapper>
    {uplines.length>0 && 
    <div className='breadcrumb-bar'>
      {uplines.map((el, index) => (
      <>
        <div className={'breadcrumb'}>
          <a href="javascript:void(0)"
            onClick={() => searchBy(el.id)}
          >
            {el.username}
          </a>
        </div>
        {index<uplines.length-1 &&
          <div className={'breadcrumb-split'}>
            <RightOutlinedIcon />
          </div>
        }
      </>
      ))}
    </div>
    }
    <TablePanel
      title={
        <div className='d-flex align-items-center flex-wrap'>
          {'Contact Manager'}
        </div>
      }
      data={tableData}
      paginationParam={paginationParam}
      loading={isLoading}
      columns={[
        {
          title: () => (
            <div className='thCell' onClick={()=>handleOrderBy('depth')}>
              Level&nbsp;
              {searchParam.order_by === 'depth' && 
              <>
                {searchParam.order_dir === 'asc' && <AiOutlineArrowUp />}
                {searchParam.order_dir === 'desc' && <AiOutlineArrowDown />}
              </>}
            </div>
          ),
          render: (_, record) => (
            <span>
              {record.level}
            </span>
          )
        },
        {
          title: () => (
            <div className='thCell' onClick={()=>handleOrderBy('uuid')}>
              User ID&nbsp;
              {searchParam.order_by === 'uuid' && 
              <>
                {searchParam.order_dir === 'asc' && <AiOutlineArrowUp />}
                {searchParam.order_dir === 'desc' && <AiOutlineArrowDown />}
              </>}
            </div>
          ),
          render: (_, record) => (
            <span>
              #{record.uuid}
            </span>
          )
        },
        {
          title: () => (
            <div className='thCell' onClick={()=>handleOrderBy('username')}>
              Username&nbsp;
              {searchParam.order_by === 'username' && 
              <>
                {searchParam.order_dir === 'asc' && <AiOutlineArrowUp />}
                {searchParam.order_dir === 'desc' && <AiOutlineArrowDown />}
              </>}
            </div>
          ),
          key: 'username',
          render: (_, record) => (
            <div className='d-flex align-items-center'>
              {/*
              <span className='username'
                onClick={() => searchBy(record.id)}
              >
                {record.username}
              </span>
              */}
              <span>{record.username}</span>
              <span 
                className='comment-icon'
                onClick={() => props.setSelectedUser(record)}
              >
                <MdOutlineComment />
              </span>
            </div>
          )
        },
        {
          title: () => (
            <div className='thCell' onClick={()=>handleOrderBy('first_name')}>
              Name&nbsp;
              {searchParam.order_by === 'first_name' && 
              <>
                {searchParam.order_dir === 'asc' && <AiOutlineArrowUp />}
                {searchParam.order_dir === 'desc' && <AiOutlineArrowDown />}
              </>}
            </div>
          ),
          key: 'name',
          render: (_, record) => (
            <div className='d-flex align-items-center'>
              <UserAvatar 
                image={record.image}
                title={`${record.first_name} ${record.last_name}`}
                status={varKey('user.status', record.status)}
              />
              {' '}
              {(record.social_messenger && 
                record.social_messenger.facebook_enabled*1===1 && 
                record.social_messenger.facebook_link
              ) && 
              <span className='chat-icon'>
                <FaFacebookMessenger 
                  onClick={()=>{
                    window.open(record.social_messenger.facebook_link, '_blank')
                  }}
                />
              </span>
              }
              {(record.social_messenger && 
                record.social_messenger.telegram_enabled*1===1 && 
                record.social_messenger.telegram_link
              ) && 
              <span className='chat-icon'>
                <FaTelegramPlane 
                  onClick={()=>{
                    window.open(record.social_messenger.telegram_link, '_blank')
                  }}
                />
              </span>
              }
              {(record.social_messenger && 
                record.social_messenger.whatsapp_enabled*1===1 && 
                record.social_messenger.whatsapp_link
              ) && 
              <span className='chat-icon'>
                <FaWhatsapp 
                  onClick={()=>{
                    window.open(record.social_messenger.whatsapp_link, '_blank')
                  }}
                />
              </span>
              }
            </div>
          )
        },
        {
          title: () => (
            <div className='thCell' onClick={()=>handleOrderBy('country')}>
              Country&nbsp;
              {searchParam.order_by === 'country' && 
              <>
                {searchParam.order_dir === 'asc' && <AiOutlineArrowUp />}
                {searchParam.order_dir === 'desc' && <AiOutlineArrowDown />}
              </>}
            </div>
          ),
          key: 'country',
          render: (_, record) => (
            <CountryFlag country={record.country} />
          )
        },
        {
          title: () => (
            <div className='thCell' onClick={()=>handleOrderBy('rank_id')}>
              Rank&nbsp;
              {searchParam.order_by === 'rank_id' && 
              <>
                {searchParam.order_dir === 'asc' && <AiOutlineArrowUp />}
                {searchParam.order_dir === 'desc' && <AiOutlineArrowDown />}
              </>}
            </div>
          ),
          key: 'rank_id',
          render: (_, record) => (
            <RankBadge 
              label={record.rank?.name}
            />
          )
        },
        {
          title: () => (
            <div className='thCell' onClick={()=>handleOrderBy('pv')}>
              PV&nbsp;
              {searchParam.order_by === 'pv' && 
              <>
                {searchParam.order_dir === 'asc' && <AiOutlineArrowUp />}
                {searchParam.order_dir === 'desc' && <AiOutlineArrowDown />}
              </>}
            </div>
          ),
          key: 'pv',
          render: (_, record) => (
            <span>{asVolume(record.qualification?.pv)}</span>
          )
        },
        {
          title: 'Subscription',
          key: 'subscription',
          render: (_, record) => (
            <span>
              {record.subscription_count*1 > 0 ? 
                <span className='subscription-yes'>Yes</span>
              : <span className='subscription-no'>No</span>}
            </span>
          )
        },
      ]}
      onPageChange={onPageChange}
      toolbar={
        <div className='d-flex align-items-center flex-wrap'>
          <div className='searchField'>
            <Input value={searchParam.uuid}
              onChange={e=>onFormInput('uuid', e.target.value)}
              placeholder={'Search by User ID, Name, Email'}
            />
          </div>
          <div className='searchField'>
            <Select value={searchParam.level}
              onChange={v=>onFormInput('level', v)}
              options={[
                { label: '', value: '' },
                ...[...Array(maxLevel).keys()].map(i => (
                { label: `Level ${i+1}`, value: i+1}
                ))
              ]}
              style={{ width: "160px" }}
            />
          </div>
          <div className='searchField'>
            <Select value={searchParam.rank_id}
              onChange={v=>onFormInput('rank_id', v)}
              options={[
                { label: '', value: '' },
                ...ranks.map(el => ({
                label: el.name, value: el.id
                }))
              ]}
              style={{ width: "150px" }}
            />
          </div>
          <div className='searchField'>
            <Select value={searchParam.status}
              onChange={v=>onFormInput('status', v)}
              options={[
                { label: '', value: '' },
                { label: 'Active', value: 'active' },
                { label: 'Inactive', value: 'inactive' },
                { label: 'Closed', value: 'closed' },
                { label: 'Purged', value: 'purged' },
              ]}
              style={{ width: "150px" }}
            />
          </div>
          <div className='searchField'>
            <Select value={searchParam.has_subscription}
              onChange={v=>onFormInput('has_subscription', v)}
              options={[
                { label: '', value: '' },
                { label: 'Has Subscription', value: 1 },
                { label: 'No Subscription', value: 2 },
              ]}
              style={{ width: "150px" }}
            />
          </div>
          <Button onClick={handleSearch}>
            Search
          </Button>
          <div style={{width: 8}} />
          <Button onClick={handleExport}
            loading={isExporting}
          >
            Export
          </Button>
        </div>
      }
    />
  </Wrapper>
  )
}

const Wrapper = styled.div`
  .panelHead {
    flex-wrap: wrap;
    .ant-radio-group {
      margin-top: 2px;
      margin-bottom: 2px;
    }
  }
  .searchField {
    margin: 2px 8px 2px 0;
    display: flex;
    align-items: center;
  }
  .thCell {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`
