import styled from "styled-components";

export const Wrapper = styled.div`
  .wallet-alert {
    margin-bottom: 20px;
  }
  .info-container {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(44, 39, 56, 0.06);
    padding: 20px;
  }
  .title {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 30px;
  }
  .ant-select {
    width: 100% !important;
    input {
      height: 38px !important;
    }
  }
  .user-country {
    margin-bottom: 20px;
  }
  .user-type {
    margin-bottom: 20px;
  }
  .input-form {
    margin-bottom: 20px;
  }
  .products {
    margin-bottom: 20px;
  }
  .error {
    color: #ff4d4d;
  }
  .quantity {
    .ant-input-number-group {
      display: flex;
      .ant-input-number-group-addon {
        cursor: pointer;
        padding: 0;
        width: auto;
        span {
          width: 30px;
          height: 100%;
          padding: 0 11px;
          display: flex;
          align-items: center;
          justify-content: center;
          user-select: none;
        }
      }
    }
    input {
      height: 38px;
      width: 90px;
      text-align: center;
    }
  }
  .add-to-cart-section {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    margin-top: 22px;
  }
  .add-cart-btn {
    height: 40px;
    margin-left: 15px;
  }
  .user-form {
    input {
      height: 40px;
    }
    .password-input {
      input {
        height: 30px;
      }
    }
    .password-visibility {
      cursor: pointer;
    }
    .dob-input {
      width: 100% !important;
      height: 40px !important;
    }
  }
  .purchase-btn {
    height: 40px;
    margin-top: 20px;
  }
  @media (max-width: 600px) {
    .ant-col {
      width: 100%;
    }
  }
  @media only screen and (max-width: 767px) {
    .quantity {
      input {
        width: 48px;
      }
    }
  }
`;
