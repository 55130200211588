export function saveSystemSettings(systemSettings) {
  localStorage.setItem('uboSystemSetting', JSON.stringify(systemSettings));
}

export function getSystemSetting(name) {
  let systemSettings = localStorage.getItem('uboSystemSetting');
  let value = undefined
  if (systemSettings) {
    systemSettings = JSON.parse(systemSettings);
    systemSettings.forEach(el => {
      if (el.name === name) {
        value = el.value
      }
    })
  }

  return value;
}

export function hasSystemSetting() {
  let systemSettings = localStorage.getItem('uboSystemSetting');
  if (systemSettings) {
    return true
  }

  return false
}
