import React, { useState } from "react";
import { Alert } from 'components/common'
import SettingSection from './setting/SettingSection'
import FailedSection from "./failed/FailedSection";
import PendingSection from "./pending/PendingSection";

export default function CurrentSubscriptionSubPage() {
  const [shouldLoad, setShouldLoad] = useState(true)

  return (
    <div>
      {/* <Alert type={'info'}
        showIcon
        style={{ alignItems: 'baseline', marginBottom: 12 }}
        message={'Your primary shipping address is used for your subscriptions and one-time purchases. If you have active subscriptions, you cannot delete your primary shipping address or change the primary shipping country. You may change your secondary shipping address at any time.'}
      /> */}
      <SettingSection setShouldLoad={setShouldLoad} />
      <FailedSection 
        shouldLoad={shouldLoad}
        setShouldLoad={setShouldLoad}
      />
      <PendingSection 
        shouldLoad={shouldLoad}
        setShouldLoad={setShouldLoad}
      />
    </div>
  );
}
