import React, { useState } from 'react';
import {
  AutoComplete,
} from 'components/common';
import {
  callGetApiWithAuth
} from 'utils/api';

export default function UserSearchForm(props) {
  const [searchedUserList, setSearchedUserList] = useState([]);
  let delayDebounceFn = null;

  const onSearchDone = ({ data }) => {
    if (data) {
      setSearchedUserList(data);
    } else {
      setSearchedUserList([]);
    }
  };

  const onSearchUser = (v) => {
    clearTimeout(delayDebounceFn);
    delayDebounceFn = setTimeout(() => {
      callGetApiWithAuth(`create_other_order/search_downliner/${v}`, onSearchDone);
    }, 1000);
  };

  const onSelectUser = (_, option) =>{
    let user = null;
    searchedUserList.forEach(el => {
      if (el.id==option.key) {
        user = el
      }
    });
    props.setSelectedUserInfo(user);
  }

  return (
    <AutoComplete 
      onSearch={onSearchUser}
      options={searchedUserList.map(el => ({
        key: el.id,
        label: `${el.first_name} ${el.last_name}`,
        value: `${el.first_name} ${el.last_name}`,
      }))}
      onSelect={onSelectUser}
      placeholder={'Search by User ID'}
      size={props.size ? props.size : "medium"}
    />
  )
}
