import React, { useRef, useState, useEffect } from 'react';
import {
  Button, Popconfirm,
} from 'components/common'

export default function UploadForm(props) {
  const fileInput = useRef(null);
  const [formData, setFormData] = useState({
    image: '',
  })
  const [errorMessages, setErrorMessages] = useState([])

  const onFileChange = (e) => {
    const fileReader = new FileReader();
    let file = e.target.files[0]
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      console.log(fileReader.result)
      setFormData({
        ...formData,
        image: fileReader.result
      });
    };
  }

  const handleContinue = () => {
    let errorMessages0 = [];
    if (!formData.image) {
      errorMessages0.push({
        field: 'image',
        message: 'Upload your photo'
      })
    }
    setErrorMessages(errorMessages0)
    if (errorMessages0.length>0) return;

    props.handleSubmit({
      ...props.userData,
      ...formData
    })
  }

  useEffect(() => {
    if (props.userData) {
      setFormData({
        ...formData,
        ...props.userData,
      })
    }
  }, [props.userData])

  return (
    <>
      <div className='form-card'>
        <h4>Upload your professional photo 2 x 2 inches (51 x 51 mm) in size</h4>
        <div className='form-group'>
          <input ref={fileInput} type="file" hidden 
            onChange={onFileChange} accept={'image/*'} 
          />
          <div className='file-select' onClick={()=>{
            fileInput.current.click();
          }}>
            {formData.image?
              <img src={formData.image} />
            : 'Click Here'}
          </div>
          {errorMessages.filter(el=>el.field==='image')
          .map(el => (
            <div className='form-error'>
              {el.message}
            </div>
          ))}
        </div>
        <div className='action-group'>
          <div>
            <Button onClick={()=>props.setStep('term')}>
              Previous
            </Button>
          </div>
          <div>
            <Popconfirm onConfirm={handleContinue}>
              <Button loading={props.isUpdating}>
                Complete
              </Button>
            </Popconfirm>
          </div>
        </div>
      </div>
    </>
  )
}
