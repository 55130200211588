import React from 'react'
import { useSelector } from 'react-redux'
import { RankBadge } from 'components/common'
import { varIs, varLabel } from 'common/var'
import { countryName } from 'common/country'
import { asDate, asVolume } from 'utils/text'
import NoPhotoIcon from 'assets/user.svg'
import { Wrapper } from './DetailBox.styled'

export default function DetailBox(props) {
  const isOpenedDetailBox = useSelector(state=>state.binaryTree.isOpenedDetailBox)
  const detailBoxPosition = useSelector(state=>state.binaryTree.detailBoxPosition)
  const detailData = useSelector(state=>state.binaryTree.detailData)
  const treeType = useSelector(state=>state.binaryTree.treeType)

  if (!isOpenedDetailBox) return ""

  return (
    <Wrapper style={{
      left: detailBoxPosition.left || 0,
      top: detailBoxPosition.top || 0,
    }}>
      <div className={'headerBar'}>
        <img className={'photo'} 
          src={detailData.image || NoPhotoIcon} 
        />
        <p className={'name'}>
          {`${detailData.first_name} ${detailData.last_name}`}
        </p>
        <p className={'userLink'}>
          {`#${detailData.uuid}`}
        </p>
        {(varIs('user.type', detailData.type, 'affiliate') && 
          treeType==='unilevel'
        ) && 
          <p className={'userLink'}>
            <RankBadge label={detailData.rank.name} />
          </p>
        }
      </div>
      <table>
        <tbody>
          {(varIs('user.type', detailData.type, 'affiliate') && 
            treeType==='unilevel'
          ) && 
          <tr>
            <td>Paid Rank</td>
            <td align={'right'}>
              {detailData.pay_rank?.name}
            </td>
          </tr>
          }
          <tr>
            <td>Country</td>
            <td align={'right'}>
              {countryName(detailData.country)}
            </td>
          </tr>
          {(treeType==='unilevel' || 
            treeType==='placement' || 
            treeType==='binary' 
          ) && 
          <tr>
            <td>Enroller</td>
            <td align={'right'}>
              {detailData.enroller?.username}
            </td>
          </tr>
          }
          {treeType==='binary' && 
          <tr>
            <td>Leg</td>
            <td align={'right'}>
              {varLabel('user.legPosition', detailData.leg_position)}
            </td>
          </tr>
          }
          <tr>
            <td>Subscription</td>
            <td align={'right'}>
              {detailData.subscription_count*1 > 0 ? 
                <span className='subscription-yes'>Yes</span>
              : <span className='subscription-no'>No</span>}
            </td>
          </tr>
          <tr>
            <td>PV</td>
            <td align={'right'}>
              {asVolume(detailData.qualification.pv)}
            </td>
          </tr>
          {(treeType==='unilevel' || 
            treeType==='placement'
          ) && 
          <tr>
            <td>Enroller Tree BV</td>
            <td align={'right'}>
              {asVolume(detailData.qualification.gv)}
            </td>
          </tr>
          }
          {(treeType==='binary') && 
          <tr>
            <td>Left Group BV</td>
            <td align={'right'}>
              {asVolume(detailData.qualification.l_gv)}
            </td>
          </tr>
          }
          {(treeType==='binary') && 
          <tr>
            <td>Right Group BV</td>
            <td align={'right'}>
              {asVolume(detailData.qualification.r_gv)}
            </td>
          </tr>
          }
          <tr>
            <td>Join Date</td>
            <td align={'right'}>
              {asDate(detailData.created_at)}
            </td>
          </tr>
        </tbody>
      </table>
    </Wrapper>
  )
}
