import React, { useState } from 'react'
import { Modal, Row, Col, Alert } from 'components/common'
import { varIs } from 'common/var'
import SummaryView from './SummaryView'
import DetailView from './DetailView'

export default function DetailModal(props) {
  const [viewMode, setViewMode] = useState('summary')
  const [bonusId, setBonusId] = useState(undefined)

  return (
    <Modal
      width={1200}
      onCancel={()=>props.setSelected(undefined)}
      title={`Earning Details (${props.selected.from} ~ ${props.selected.to})`}
    >
      {varIs('userBonus.isOld', props.selected.is_old, 'yes') && 
      <div>
        <Alert
          className="welcome-alert"
          message={<label>This commission was processed on the old system so the earnings summary is not available. For questions regarding this payout, please contact <a href="mailto:support@mydailychoice.com">support@mydailychoice.com</a>.</label>}
          type="info"
          style={{
            marginBottom: 20
          }}
          showIcon
        />
      </div>
      }
      {viewMode==='summary' && 
        <SummaryView selected={props.selected}
          setViewMode={setViewMode}
          setBonusId={setBonusId}
        />
      }
      {viewMode==='detail' && 
        <DetailView selected={props.selected}
          setViewMode={setViewMode}
          bonusId={bonusId}
        />
      }
    </Modal>
  )
}
