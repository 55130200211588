import React, { useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

export default function StartEndDatePicker({
  value, onChange, ...props
}) {
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  useEffect(() => {
    if (value) {
      setStartDate(value.split('|')[0])
      setEndDate(value.split('|')[1])
    } else {
      setStartDate('')
      setEndDate('')
    }
  }, [value])

  return (
    <RangePicker
      value={(startDate && endDate) ? 
        [moment(startDate), moment(endDate)] 
      : ''}
      onChange={(_, v) => {
        if (v) {
          onChange(v[0]+'|'+v[1])
        } else {
          onChange('')
        }
      }}
      style={{
        height: props.height || '34px',
        width: props.width || 'auto',
      }}
      {...props}
    />
  );
};
