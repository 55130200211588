import React, { useState, useEffect } from "react"
import moment from "moment"
import { varIs } from "common/var"
import { 
  Row, Col, FormItem, Modal,
  Button, Input, Select, DatePicker,
  SuccessNotification, Popconfirm, WarningNotification
} from "components/common"
import { callGetApiWithAuth, callPostApiWithAuth } from "utils/api"
import { asPrice } from "utils/text"
import { ReactComponent as AddIcon } from "assets/addRound.svg"
import { ReactComponent as DeleteIcon } from "assets/deleteRed.svg"
import { Wrapper } from "./AddModal.styled"

export default function AddModal(props) {
  const [formData, setFormData] = useState({
    pay_date: moment().add(1, 'days'),
  })
  const [details, setDetails] = useState([{
    product: undefined, product_id: '',
    quantity: 1, pay_cycle: 0
  }])
  const [productOptions, setProductOptions] = useState([])
  const [isUpdating, setIsUpdating] = useState(false)

  const onChangeDate = (value) => {
    const date0 = new Date(value)
    if (date0.getDate() > 28) {
      WarningNotification("Please select a date less than 29 days old.")
      return
    }
    setFormData({
      ...formData,
      pay_date: value
    })
  }

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  }

  const onGetProductOptions = (res) => {
    setProductOptions(res.data)
  }
  const loadProductOptions = () =>{
    callGetApiWithAuth(`my_account/subscription/products`, 
      onGetProductOptions
    )
  }
  
  const addRow = () => {
    const details0 = [...details]
    details0.push({
      product: undefined, product_id: '',
      quantity: 1, pay_cycle: 0
    })
    setDetails(details0)
  }

  const removeRow = (index) => {
    const details0 = details.filter((_, index0) => index!=index0)
    setDetails(details0)
  }

  const onDetailInput = (index, field, value) => {
    let details0 = [...details]
    details0[index][field] = value
    if (field==='product_id') {
      let product = undefined
      productOptions.forEach(el => {
        if (el.id === value) {
          product = el
        }
      })
      details0[index]['product'] = product
      if (product && !varIs("product.type", product.type, "physical")) {
        details0[index]['quantity'] = 1
      }
      details0[index]['pay_cycle'] = 0
    }
    setDetails(details0)
  }

  const onSubmitDone = (res) => {
    setIsUpdating(false)
    SuccessNotification("Subscription has been created successfully")
    props.handleSuccessCreate()
  }
  const onSubmitFail = () => {
    setIsUpdating(false)
  }
  const handleSubmit = () => {
    // Validation
    if (!formData.pay_date) {
      WarningNotification('Please select pay date')
      return
    }
    for (let el of details) {
      if (!el.product) {
        WarningNotification('Please select product')
        return
      }
      if (!isNaN(el.quantity) && 
        el.quantity<=0
      ) {
        WarningNotification('Please input qty greater than 0')
        return
      }
      if (!el.pay_cycle || el.pay_cycle === 0) {
        WarningNotification('Please select frequency')
        return
      }
    }

    setIsUpdating(true)
    const body = {
      pay_date: moment(formData.pay_date).format("YYYY-MM-DD"),
      details
    }
    callPostApiWithAuth(`my_account/subscription/create`, body, 
      onSubmitDone, onSubmitFail
    )
  }

  useEffect(() => {
    loadProductOptions()
  }, [])

  return (
    <Modal title='Create Subscription'
      width={900}
      onCancel={props.handleCloseAddSubscriptionModal}
    >
      <Wrapper>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <div className="input-container">
              <FormItem label={"Pay At"}>
                <DatePicker
                  value={formData.pay_date} 
                  onChange={(value) => onChangeDate(value)}
                  disabledDate={disabledDate}
                  size="small"
                  className="date-picker-dob"
                />
              </FormItem>
            </div>
          </Col>
          <Col xs={24}>
            {details.map((el, index) => (
              <Row gutter={[15, 15]} className="product-row" key={index}>
                <Col xs={24} sm={8}>
                  <div className="input-container">
                    <FormItem label={"Product"}>
                      <Select
                        value={el.product_id}
                        onChange={v=>onDetailInput(index, 'product_id', v)}
                        options={productOptions.map(el2 => ({
                          label: el2.title,
                          value: el2.id,
                          key: el2.id
                        }))}
                        style={{ width: "100%" }}
                        showSearch
                        optionFilterProp="label"
                      />
                    </FormItem>
                  </div>
                </Col>
                <Col xs={12} sm={4}>
                  <div className="input-container">
                    <FormItem label={"Unit Price"}>
                      <Input
                        value={asPrice(el.product ? el.product.recurring_price : 0)} 
                        disabled 
                      />
                    </FormItem>
                  </div>
                </Col>{" "}
                <Col xs={12} sm={3}>
                  <div className="input-container">
                    <FormItem label={"QTY"}>
                      <Input type='number'
                        value={el.quantity}
                        onChange={e=>onDetailInput(index, 'quantity', e.target.value)}
                        disabled={
                          (
                            details && 
                            details.length > 0 && 
                            details[index] && 
                            details[index].product &&
                            !varIs("product.type", details[index].product.type, "physical")
                          ) ? true : false
                        }
                      />
                    </FormItem>
                  </div>
                </Col>
                <Col xs={12} sm={7}>
                  <div className="input-container">
                    <FormItem label={"Subscription Frequency"}>
                      <Select
                        value={el.pay_cycle*1}
                        options={
                          (el.product && !varIs("product.purchaseType", el.product.purchase_type, "onetime"))?[
                            { label: 'None', value: 0 },
                            ...el.product.pay_cycles.map(el2 => ({
                              label: el2*1===1 ? 'Every Year' : el2*1===30 ? 'Every Month' : el2*1===60 ? 'Every 2 Months' : el2*1===90 ? 'Every 3 Months' : '',
                              value: el2*1
                            }))
                          ] : [
                            { label: 'None', value: 0 },
                          ]
                        }
                        onChange={v=>onDetailInput(index, 'pay_cycle',v)}
                        style={{ width: "100%" }}
                      />
                    </FormItem>
                  </div>
                </Col>
                <Col xs={4} sm={2}>
                  <div className="icon-container">
                    {index === details.length - 1 ? (
                      <>{index !== 0 &&
                          <DeleteIcon
                            onClick={() => removeRow(index)}
                          />
                        }
                        <AddIcon onClick={()=>addRow()} />
                      </>
                    ) : (
                      <DeleteIcon
                        onClick={() => removeRow(index)}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            ))}
          </Col>{" "}
          <Col span={24}>
            <div className="action-btn">
              <Popconfirm onConfirm={handleSubmit}>
                <Button loading={isUpdating}>
                  Create
                </Button>
              </Popconfirm>
            </div>
          </Col>
        </Row>
      </Wrapper>
    </Modal>    
  );
}
