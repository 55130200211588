import React, { useEffect, useState } from 'react';
import {
  Select, Button,
} from 'components/common'

export default function GiftForm(props) {
  const [formData, setFormData] = useState(props.giftSetting)
  const [errorMessages, setErrorMessages] = useState([])

  const onFormInput = (field, value) => {
    setFormData({
      ...formData,
      [field]: value
    })
  }

  const handleContinue = () => {
    let errorMessages0 = [];
    [
      'cap_size', 'shirt_size',
    ].forEach(k => {
      if (!formData[k]) {
        errorMessages0.push({
          field: k,
          message: 'Required'
        })
      }
    })
    setErrorMessages(errorMessages0)
    if (errorMessages0.length>0) return;

    props.setGiftSetting({
      ...props.giftSetting,
      ...formData,
    })
    props.setStep('cc')
  }

  useEffect(() => {
    if (props.giftSetting) {
      setFormData({
        ...formData,
        ...props.giftSetting,
      })
    }
  }, [props.giftSetting])

  return (
    <>
      <div className='form-card'>
        <h4>Select your Apparel sizes</h4>
        <div className='form-group'>
          <label>Cap Size</label><br/>
          <Select value={formData.cap_size}
            onChange={v=>onFormInput('cap_size', v)}
            options={[
              { value: 'Small', label: 'Small' },
              { value: 'Medium', label: 'Medium' },
              { value: 'Large', label: 'Large' },
            ]}
          />
          {errorMessages.filter(el=>el.field==='cap_size')
          .map(el => (
            <div className='form-error'>
              {el.message}
            </div>
          ))}
        </div>
        <div className='form-group'>
          <label>Shirt Size</label><br/>
          <Select value={formData.shirt_size}
            onChange={v=>onFormInput('shirt_size', v)}
            options={[
              { value: 'XS', label: 'XS' },
              { value: 'Small', label: 'Small' },
              { value: 'Medium', label: 'Medium' },
              { value: 'Large', label: 'Large' },
              { value: 'XL', label: 'XL' },
              { value: 'XXL', label: 'XXL' },
              { value: 'XXXL', label: 'XXXL' },
            ]}
          />
          {errorMessages.filter(el=>el.field==='shirt_size')
          .map(el => (
            <div className='form-error'>
              {el.message}
            </div>
          ))}
        </div>
        <div className='action-group'>
          <div>
            <Button onClick={()=>props.setStep('shipping')}>
              Previous
            </Button>
          </div>
          <div>
            <Button onClick={handleContinue}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
