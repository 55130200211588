import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";
import Helmet from "react-helmet";
import styled from "styled-components";
import { Spin } from 'components/common'
import { callGetApi } from "utils/api";
import { isAuthedUser } from "utils/auth";
import {
  saveSystemSettings,
  getSystemSetting,
  hasSystemSetting,
} from 'utils/systemSetting'
import BaseLayout from "../base/BaseLayout";
import RightBgImg from "assets/login_right_bg.svg";
import DefFavicon from 'assets/logo.png'

export default function AuthLayout({ children }) {
  const history = useHistory();
  const dispatch = useDispatch()
  const [isLoadingSetting, setIsLoadingSetting] = useState(false)

  const onGetSystemSettings = (res) => {
    setIsLoadingSetting(false)
    saveSystemSettings(res.data)
    res.data.forEach(el=> {
      if (el.name === 'theme') {
        dispatch({
          type: 'systemSetting.SET_THEME',
          payload: {
            theme: el.value
          }
        })
      }
    })
  }
  const loadSystemSettings = () => {
    setIsLoadingSetting(true)
    callGetApi(`common/system_settings`, onGetSystemSettings)
  }

  useEffect(() => {
    if (isAuthedUser()) {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (document.getElementsByTagName("body").length > 0) {
        let bodySelector = document.getElementsByTagName("body")[0];
        bodySelector.classList.add('login-page');
      }
    }
  }, []);

  useEffect(() => {
    loadSystemSettings()
  }, [])

  return (
    <BaseLayout>
      <Wrapper>
        {isLoadingSetting &&
          <div className='loading-root'>
            <Spin />
          </div>
        }
        {hasSystemSetting() &&  
        <>
          <div className={"left-bg"}
            style={{
              backgroundImage: `url(${getSystemSetting('login_bg')})`
            }}
          >
            <div className="left-logo">
              <img src={getSystemSetting('login_logo')} alt="left-bg" />
            </div>
          </div>
          <div className={"right-root"}>{children}</div>
        </>}
      </Wrapper>
      <Helmet>
        <link rel='icon' type='image/x-icon'
          href={getSystemSetting('favicon') || DefFavicon}
        />
      </Helmet>
    </BaseLayout>
  );
}

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  background: #f8f8f8;
  position: relative;
  .loading-root {
    position: absolute;
    left: 0; right: 0;
    top: 0; bottom: 0;
    opacity: 0.6;
    z-index: 10;
  }
  .left-bg {
    width: 67%;
    height: 100%;
    position: relative;
    padding: 30px 40px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    .left-logo {
      width: 130px;
      position: absolute;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .main-logo {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      height: 100%;
      padding-right: 4rem !important;
      padding-left: 4rem !important;
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
  .right-root {
    width: 33%;
    background: white !important;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(${RightBgImg});
    background-position: right;
  }
  @media (max-width: 1000px) {
    .left-bg {
      display: none;
    }
    .right-root {
      width: 100%;
    }
  }
`;
