import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import moment from 'moment'
import { callGetApiWithAuth } from 'utils/api'
import { countryName, regionByCountry } from 'common/country'
import { asCurrencyPrice } from 'utils/text'
import { varIs, varIsIn } from 'common/var'

export default function OrderReceiptPage(props) {
  const { orderId } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [orderData, setOrderData] = useState(undefined)
  const [groupedByTax, setGroupedByTax] = useState({})

  const onGetOrderDetail = (res) => {
    setIsLoading(false)
    setOrderData(res.data)
    let groupedByTax0 = []
    res.data.details.forEach(el => {
      if (el.tax_rate>0) {
        if (el.tax_rate in groupedByTax0) {
          groupedByTax0[el.tax_rate].push(el)
        } else {
          groupedByTax0[el.tax_rate] = [el]
        }
      }
    })
    setGroupedByTax(groupedByTax0)
  }
  const onFailOrderDetail = () => {
    setIsLoading(false)
  }
  useEffect(() => {
    setIsLoading(true)
    callGetApiWithAuth(`my_account/order_receipt/order/${orderId}`, 
      onGetOrderDetail, onFailOrderDetail
    )
  }, [])

  return (
    <Wrapper>
    {orderData?
      <div className='content-root'>
        {(orderData?.user?.country && regionByCountry(orderData.user.country) && regionByCountry(orderData.user.country) === 'Europe') ?
          <div className='europe-header'>
            <h5>Invoice</h5>
            <h2>My Daily Choice B.V.</h2>
            <ul>
              <li>De Cuserstraat 89, 1081 CN Amsterdam</li>
              <li>+31 20 303 47 62</li>
              <li>VAT Number: NL 861154630.B01</li>
              <li>Chamber of Commerce Number: 77809718</li>
            </ul>
          </div>
          :
          <>
            <h2>MyDailyChoice</h2>
            <h6>RECEIPT</h6>
          </>
        }
        <div>
          <h4>Order Info</h4>
          <table>
            <tr>
              <td>Order Number:</td>
              <td style={{ textAlign: "right", }}>{orderData.order_number}</td>
            </tr>
            <tr>
              <td>Invoice Number:</td>
              <td style={{ textAlign: "right", }}>{orderData.order_number}</td>
            </tr>
            <tr>
              <td>Order Date:</td>
              <td style={{ textAlign: "right", }}>{moment(orderData.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
            </tr>
            <tr>
              <td>PV:</td>
              <td style={{ textAlign: "right", }}>{orderData.order_total_pv}</td>
            </tr>
            <tr>
              <td>BV:</td>
              <td style={{ textAlign: "right", }}>{orderData.order_total_cv}</td>
            </tr>
          </table>
          <hr />
          <h4>User Info</h4>
          <table>
            <tr>
              <td>User ID:</td>
              <td style={{ textAlign: "right", }}>{orderData.user.uuid}</td>
            </tr>
            <tr>
              <td>First Name:</td>
              <td style={{ textAlign: "right", }}>{orderData.user.first_name}</td>
            </tr>
            <tr>
              <td>Last Name:</td>
              <td style={{ textAlign: "right", }}>{orderData.user.last_name}</td>
            </tr>
            {orderData.user.vat_number?
            <tr>
              <td>VAT Number:</td>
              <td style={{ textAlign: "right", }}>{orderData.user.vat_number}</td>
            </tr>
            : ''}
            <tr>
              <td>E-Mail:</td>
              <td style={{ textAlign: "right", }}>{orderData.user.email}</td>
            </tr>
            <tr>
              <td>Phone:</td>
              <td style={{ textAlign: "right", }}>{orderData.user.phone}</td>
            </tr>
            <tr>
              <td>Username:</td>
              <td style={{ textAlign: "right", }}>{orderData.user.username}</td>
            </tr>
            {orderData.user.company_name?
            <tr>
              <td>Company:</td>
              <td style={{ textAlign: "right", }}>{orderData.user.company_name}</td>
            </tr>
            : ''}
          </table>
          <hr />
          <h4>Order Details</h4>
          <table>
            <thead>
              <tr>
                <th style={{
                    textAlign: "left",
                    paddingLeft: 5,
                  }}
                >Title</th>
                <th style={{ textAlign: "right", }}>Qty</th>
                <th style={{ textAlign: "right", }}>Price</th>
                <th style={{ textAlign: "right", }}>Line Total</th>
              </tr>
            </thead>
            <tbody>
              {orderData.details.map(orderDetail => 
                <tr>
                  <td style={{
                    textAlign: "left",
                    paddingLeft: 5,
                  }}>{orderDetail.product.title}</td>
                  <td style={{ textAlign: "right", }}>
                    {orderDetail.quantity}
                  </td>
                  <td style={{ textAlign: "right", }}>
                    {asCurrencyPrice(orderDetail.local_price, orderData.currency_code)}
                  </td>
                  <td style={{ textAlign: "right", }}>
                    {asCurrencyPrice(orderDetail.local_total_amount, orderData.currency_code)}
                  </td>
                </tr>
              )}
              {Object.keys(groupedByTax).length>0 && 
                <tr>
                  <td colSpan={4}>&nbsp;</td>
                </tr>
              }
              {Object.keys(groupedByTax).map(k => {
                let amount = 0
                groupedByTax[k].forEach(el => {
                  amount += el.local_total_amount*1
                })

                return (
                  <tr>
                    <td style={{
                      textAlign: "left",
                      paddingLeft: 5,
                    }}>
                      Product total @ VAT {(k*1).toFixed(2)}%
                    </td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: "right", }}>
                      {asCurrencyPrice(amount, orderData.currency_code)}
                    </td>
                  </tr>
                )
              })}
              {Object.keys(groupedByTax).map(k => {
                let amount = 0
                groupedByTax[k].forEach(el => {
                  amount += el.local_tax_amount*1
                })

                return (
                  <tr>
                    <td style={{
                      textAlign: "left",
                      paddingLeft: 5,
                    }}>
                      Product VAT @ {(k*1).toFixed(2)}%
                    </td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: "right", }}>
                      {asCurrencyPrice(amount, orderData.currency_code)}
                    </td>
                  </tr>
                )
              })}
              <tr>
                <td colSpan={4}>&nbsp;</td>
              </tr>
              <tr>
                <td style={{
                  textAlign: "left",
                  paddingLeft: 5,
                }}>
                  Shipping and Handling
                </td>
                <td></td>
                <td></td>
                <td style={{ textAlign: "right", }}>
                  {asCurrencyPrice(orderData.local_org_shipping_price, orderData.currency_code)}
                </td>
              </tr>
              {orderData.local_shipping_vat_price>0 && 
              <tr>
                <td style={{
                  textAlign: "left",
                  paddingLeft: 5,
                }}>
                  S &amp; H VAT @ {(orderData.shipping_vat_rate*1).toFixed(2)}%
                </td>
                <td></td>
                <td></td>
                <td style={{ textAlign: "right", }}>
                  {asCurrencyPrice(orderData.local_shipping_vat_price, orderData.currency_code)}
                </td>
              </tr>
              }
              <tr>
                <td colSpan={4}>&nbsp;</td>
              </tr>
              <tr>
                <td style={{
                  textAlign: "left",
                  paddingLeft: 5,
                }}>
                  Sales Tax
                </td>
                <td></td>
                <td></td>
                <td style={{ textAlign: "right", }}>
                  {asCurrencyPrice(orderData.local_tax_amount, orderData.currency_code)}
                </td>
              </tr>
              {orderData.local_shipping_vat_price>0?
                <tr>
                  <td style={{
                    textAlign: "left",
                    paddingLeft: 5,
                  }}>
                    VAT Total
                  </td>
                  <td></td>
                  <td></td>
                  <td style={{ textAlign: "right", }}>
                    {asCurrencyPrice(
                      orderData.local_tax_amount*1+orderData.local_shipping_vat_price*1,
                      orderData.currency_code
                    )}
                  </td>
                </tr>
              : ''}
              <tr>
                <td style={{
                  textAlign: "left",
                  paddingLeft: 5,
                }}>
                  <strong>Order Total</strong>
                </td>
                <td></td>
                <td></td>
                <td style={{ textAlign: "right", }}>
                  {varIs('order.payType', orderData.pay_type, 'coin')?
                    `${orderData.merchant_amount} (${orderData.coin_type.toUpperCase()})`
                  : 
                    asCurrencyPrice(orderData.local_order_total_amount, orderData.currency_code)
                  }
                </td>
              </tr>
            </tbody>
          </table>
          <hr />
          <h4>Shipping Details</h4>
          <table>
            <thead>
              <tr>
                <th style={{
                    textAlign: "left",
                    paddingLeft: 5,
                  }}
                >Title</th>
                <th style={{ textAlign: "right", }}>Qty</th>
              </tr>
            </thead>
            <tbody>
              {orderData.shipping_details.map(orderDetail => 
                <tr>
                  <td style={{
                    textAlign: "left",
                    paddingLeft: 5,
                  }}>{orderDetail.product.title}</td>
                  <td style={{ textAlign: "right", }}>
                    {orderDetail.quantity}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {orderData.billing_country && 
          <>
            <hr />
            <h4>Billing Info</h4>
            <table>
              <tr>
                <td>Address Line 1:</td>
                <td style={{ textAlign: "right", }}>
                  {varIsIn('order.payType', orderData.pay_type, ['cc', 'bank']) ?
                    (orderData.billing_address || '-')
                  : (orderData.shipping_address || '-')}
                </td>
              </tr>
              <tr>
                <td>Address Line 2(optional):</td>
                <td style={{ textAlign: "right", }}>
                  {varIsIn('order.payType', orderData.pay_type, ['cc', 'bank']) ?
                    (orderData.billing_address_line2 || '-')
                  : (orderData.shipping_address_line2 || '-')}
                </td>
              </tr>
              <tr>
                <td>City:</td>
                <td style={{ textAlign: "right", }}>
                  {varIsIn('order.payType', orderData.pay_type, ['cc', 'bank']) ?
                    (orderData.billing_city || '-')
                  : (orderData.shipping_city || '-')}
                </td>
              </tr>
              <tr>
                <td>State/Province:</td>
                <td style={{ textAlign: "right", }}>
                  {varIsIn('order.payType', orderData.pay_type, ['cc', 'bank']) ?
                    (orderData.billing_state || '-')
                  : (orderData.shipping_state || '-')}
                </td>
              </tr>
              <tr>
                <td>ZIP/Postal Code:</td>
                <td style={{ textAlign: "right", }}>
                  {varIsIn('order.payType', orderData.pay_type, ['cc', 'bank']) ?
                    (orderData.billing_zipcode || '-')
                  : (orderData.shipping_zipcode || '-')}
                </td>
              </tr>
              <tr>
                <td>Country:</td>
                <td style={{ textAlign: "right", }}>
                  {varIsIn('order.payType', orderData.pay_type, ['cc', 'bank']) ?
                    (countryName(orderData.billing_country) || '-')
                  : (countryName(orderData.shipping_country) || '-')}
                </td>
              </tr>
            </table>
          </>
          }
          <hr />
          <h4>Shipping Info</h4>
          <table>
            <tr>
              <td>Address Line 1:</td>
              <td style={{ textAlign: "right", }}>{orderData.shipping_address || "-"}</td>
            </tr>
            <tr>
              <td>Address Line 2(optional):</td>
              <td style={{ textAlign: "right", }}>{orderData.shipping_address_line2 || "-"}</td>
            </tr>
            <tr>
              <td>City:</td>
              <td style={{ textAlign: "right", }}>{orderData.shipping_city || "-"}</td>
            </tr>
            <tr>
              <td>State/Province:</td>
              <td style={{ textAlign: "right", }}>{orderData.shipping_state || "-"}</td>
            </tr>
            <tr>
              <td>ZIP/Postal Code:</td>
              <td style={{ textAlign: "right", }}>{orderData.shipping_zipcode || "-"}</td>
            </tr>
            <tr>
              <td>Country:</td>
              <td style={{ textAlign: "right", }}>{orderData.shipping_country ? countryName(orderData.shipping_country) : "-"}</td>
            </tr>
          </table>
        </div>  
      </div>
      : ''}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: #fff;
  .content-root {
    max-width: 480px;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }  
  table {
    width: 100%;
  }
  h2 {
    font-size: 24px;
    text-align: center;
    margin-top: 40px;
  }  
  h6 {
    margin: 32px 0;
    font-size: 16px;
    text-align: center;
  }
  h4 {
    color: rgb(112, 189, 230);
    margin: 15px 0 7px;
    font-size: 18px;
  }
  .europe-header {
    h2 {
      margin-top: 20px;
    }
    h5 {
      text-align: center;
      font-size: 22px;
      justify-content: center;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 30px 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      color: rgb(112,189,230);
    }
  }
`
