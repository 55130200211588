import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { 
  VideoModal, List, Spin ,
  Button, Select, 
} from "components/common";
import { varOptionsWithDefault } from "common/var";
import { callGetApiWithAuth } from "utils/api";
import { ReactComponent as PlayIcon } from "assets/play.svg";

export default function GetStartedPage() {
  const [searchParam, setSearchParam] = useState({
    lang: "",
    asset_type: "",
  });
  const [listData, setListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onGetListData = (res) => {
    setListData(res.data);
    setIsLoading(false);
  };
  const onFailListData = () => {
    setIsLoading(false);
  };
  const loadListData = (searchParam0) => {
    setIsLoading(true);
    const params = {
      ...searchParam0,
    };
    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
    callGetApiWithAuth(
      `tool/get_started/list?${q}`,
      onGetListData,
      onFailListData
    );
  };

  const handleSearch = () => {
    loadListData(searchParam);
  };

  const onSearchInput = (field, value) => {
    const searchParam0 = {
      ...searchParam,
      [field]: value,
    };
    setSearchParam(searchParam0);
  };

  useEffect(() => {
    handleSearch();
  }, [searchParam]);

  return (
    <Wrapper>
      <div className="main-container">
        <div className="header-search">
          <div className="marketing-title">Getting Started</div>
          {/* <Row gutter={[24, 24]}>
            <Col xs={24} xl={6} xxl={6}>
              
            </Col>
            <Col xs={24} xl={8} xxl={6}>
              <Select
                label="Select Language"
                value={searchParam.lang}
                onChange={(v) => onSelect("lang", v)}
                options={varOptionsWithDefault(
                  "resource.lang",
                  "Select Language"
                )}
              />
            </Col>
            <Col xs={24} xl={8} xxl={6}>
              <Select
                label="All Asset Types"
                value={searchParam.asset_type}
                onChange={(v) => onSearchInput("asset_type", v)}
                options={varOptionsWithDefault(
                  "resource.assetType",
                  "All Asset Types"
                )}
              />
            </Col>
          </Row> */}
        </div>
        <List
          grid={{ gutter: [24, 24], xs: 1, sm: 2, md: 2, lg: 3, xl: 3, xxl: 4 }}
          dataSource={listData}
          renderItem={(item) => (
            <List.Item className="card">
              <ListCard data={item} />
            </List.Item>
          )}
          loading={{
            spinning: isLoading,
            indicator: <Spin spinning={true} className={`spinner`} />,
          }}
          className="cards-list"
        />
      </div>
    </Wrapper>
  )
}

const ListCard = ({ data }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handleWatchVideo = () => {
    setIsPlaying(true);
  };

  const handleDownload = () => {
    setIsPlaying(false);
    window.open(data.file, "_blank");
  };

  return (
    <>
      <div className="white-rounded-bg training-cards-container">
        <div>
          <div className="training-background">
            <img src={data.thumbnail} alt={"Market Asset Video"} />
            {data.file_type * 1 === 4 && (
              <PlayIcon onClick={() => setIsPlaying(true)} />
            )}
          </div>
          <div className="training-title">{data.title}</div>
          <div className="training-content">{data.description}</div>
        </div>
        <div className="watch-now-container">
          <Button
            onClick={
              data.file_type * 1 === 4 ? handleWatchVideo : handleDownload
            }
          >
            {data.file_type * 1 === 4 ? "Watch Now" : "Download"}
          </Button>
        </div>
      </div>
      {data.file_type * 1 === 4 && isPlaying && (
        <VideoModal
          onCancel={() => setIsPlaying(false)}
          src={data.file}
          width={1200}
          isVimeo
        />
      )}
    </>
  );
}

const Wrapper = styled.div`
  .main-container {
    padding-bottom: 60px;
    .cards-list {
      .ant-col {
        height: 100%;
        .card {
          height: 100%;
          border: none;
        }
      }
    }
  }
  .header-search {
    margin-bottom: 24px;
    .ant-select {
      width: 100%;
      height: 40px !important;
    }
    .marketing-title {
      margin-top: 10px;
      color: #212b36;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
    }
  }
  .training-cards-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    padding: 20px;
    .watch-now-container {
      button {
        width: 100%;
      }
    }
    .training-background {
      height: 202px;
      position: relative;
      svg {
        opacity: 0.5;
        position: absolute;
        left: calc(50% - 27px);
        top: calc(50% - 27px);
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }
    .training-title {
      font-weight: 600;
      font-size: 16px;
      margin-top: 24px;
      color: #212b36;
      overflow: hidden;
      white-space: nowrap !important;
      text-overflow: ellipsis;
    }
    .training-content {
      font-size: 14px;
      line-height: 22px;
      color: #637381;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 6;
      overflow: hidden;
    }
  }
`;
