import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import Helmet from "react-helmet";
import { Layout, ErrorNotification } from "components/common";
import { varIs } from "common/var";
import { hasPageAccess } from "common/menu";
import { isAuthedUser, getSudo, saveStorage, getToken } from "utils/auth";
import { callGetApi, callGetApiWithAuth } from "utils/api";
import { saveSystemSettings, getSystemSetting, hasSystemSetting } from "utils/systemSetting";
import BaseLayout from "../base/BaseLayout";
import TopHeader from './TopHeader'
import DefFavicon from 'assets/logo.png'

const { Header, Content } = Layout;

export default function StartedLayout({ children, pathname }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const myUser = useSelector((state) => state.auth.user);
  const sudoName = useSelector((state) => state.auth.sudo_name);
  const errorMessage = useSelector((state) => state.api.errorMessage);
  const needReload = useSelector((state) => state.auth.needReload);

  const onGetProfile = (res) => {
    dispatch({ type: "auth.RELOAD_DONE" });
    saveStorage(res.data, getToken(), getSudo());
    dispatch({
      type: "auth.SET_USER",
      payload: {
        user: res.data,
        sudoName: getSudo(),
      },
    });
  };
  const onFailProfile = () => {
    dispatch({ type: "auth.RELOAD_DONE" });
  };

  const onGetSystemSettings = (res) => {
    saveSystemSettings(res.data)
    res.data.forEach(el=> {
      if (el.name === 'theme') {
        dispatch({
          type: 'systemSetting.SET_THEME',
          payload: {
            theme: el.value
          }
        })
      }
    })
  }
  const loadSystemSettings = () => {
    callGetApi(`common/system_settings`, onGetSystemSettings)
  }

  useEffect(() => {
    document.getElementById("myapp").scroll(0, 0);
    if (!isAuthedUser()) {
      history.push("/logout");
      return;
    }
  }, [history?.location?.pathname]);

  useEffect(() => {
    if (errorMessage) {
      ErrorNotification(errorMessage);
      dispatch({
        type: "api.CLEAR_ERROR_MESSAGE",
      });
    }
  }, [errorMessage]);

  useEffect(() => {
    setTimeout(() => {
      callGetApiWithAuth(`common/profile`, onGetProfile, onFailProfile);
    }, 1500);
    
    if (hasSystemSetting()) {
      dispatch({
        type: 'systemSetting.SET_THEME',
        payload: {
          theme: getSystemSetting('theme')
        }
      })
    } else {
      history.push('/logout')
    }
  }, []);

  useEffect(() => {
    if (needReload) {
      callGetApiWithAuth(`common/profile`, onGetProfile, onFailProfile);
    }
  }, [needReload]);

  useEffect(() => {
    // Check Page Access
    if (myUser && history.location.pathname) {
      if (!hasPageAccess(history.location.pathname, myUser, sudoName)) {
        if (varIs("user.taxStatus", myUser.tax_status, "requested")) {
          history.push("/tax_form");
          return;
        }
        if (
          varIs(
            "user.verificationStatus",
            myUser.verification_status,
            "requested"
          )
        ) {
          history.push("/verification");
          return;
        }

        if (myUser.status*1===2) {
          history.push('/get_started');
        } else {
          history.push("/");
        }
      }
    }
  }, [myUser, history.location.pathname]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (document.getElementsByTagName("body").length > 0) {
        let bodySelector = document.getElementsByTagName("body")[0];
        bodySelector.classList.remove("login-page");
      }
    }
  }, []);

  return myUser ? (
    <BaseLayout>
      <LayoutWrapper>
        <Layout>
          <Header className="site-layout-background" 
            style={{ padding: 0 }}
          >
            <TopHeader pathname={pathname} 
            />
          </Header>
          <Content
            className="site-layout-background"
            style={{
              padding: 20,
            }}
          >
            <Wrapper className={"pageContent"}>
              <div 
                style={{
                  height: "100%",
                }}
              >
                {children}
              </div>
            </Wrapper>
          </Content>
        </Layout>
      </LayoutWrapper>
      <Helmet>
        <link rel='icon' type='image/x-icon'
          href={getSystemSetting('favicon') || DefFavicon}
        />
      </Helmet>
    </BaseLayout>
  ) : (
    ""
  );
}

const Wrapper = styled.div`
  height: 100%;
`;
const LayoutWrapper = styled.div`
  height: 100%;
  .site-layout {
    margin-left: 270px;
    transition: margin-left 0.7s;
    transition-timing-function: ease-in-out;
  }
`;
