import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { callGetApiWithAuth } from 'utils/api'
import TreeView from 'components/treeView/TreeView'
import { Pagination } from 'components/common'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

export default function TreeSubPage() {
  const dispatch = useDispatch()
  const nodeCount = 1
  const [isLoading, setIsLoading] = useState(false)
  const [treeData, setTreeData] = useState(undefined)
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1, perPage: 100, total: 0
  })
  const shouldSearchAgain = useSelector(state=>state.binaryTree.shouldSearchAgain)
  const searchingUserId = useSelector(state=>state.binaryTree.searchingUserId)  

  const onGetTreeData = (res) => {
    setIsLoading(false)
    
    setTreeData(res.data.data)
    setPaginationParam({
      ...paginationParam,
      currentPage: res.data.current_page,
      total: res.data.total,
    })
    dispatch({ type: 'binaryTree.SEARCH_DONE' })
  }
  const onFailTreeData = () => {
    setIsLoading(false)
    dispatch({ type: 'binaryTree.SEARCH_DONE' })
  }
  const loadTreeData = (userId, paginationParam0) => {
    setIsLoading(true)
    setTreeData(undefined)
    const params = {
      user_id: userId,
      nodes: nodeCount,
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    }
    dispatch({ type: 'binaryTree.CLOSE_DETAIL_BOX' })
    const q = Object.keys(params).map(k=>k+'='+params[k]).join('&')
    callGetApiWithAuth(`my_team/unilevel_tree/tree?${q}`, onGetTreeData, onFailTreeData)
  }
  const loadRoot = () => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1
    }
    loadTreeData('', paginationParam0)
    dispatch({ type: 'binaryTree.INIT', payload: { tree_type: 'unilevel' } })
  }
  const loadParent = () => {
    if (treeData.enroller_id) {
      const paginationParam0 = {
        ...paginationParam,
        currentPage: 1
      }
      loadTreeData(treeData.enroller_id, paginationParam0)
      dispatch({ 
        type: 'binaryTree.SET_SEARCHING_ID',
        payload: {
          user_id: treeData.enroller_id
        }
      })
    }
  }
  const search = (userId) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1
    }
    dispatch({ 
      type: 'binaryTree.SET_SEARCHING_ID',
      payload: {
        user_id: userId
      }
    })
    loadTreeData(userId, paginationParam0)
  }  
  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page
    }
    loadTreeData(searchingUserId, paginationParam0)
  }

  useEffect(() => {
    if (shouldSearchAgain) {
      const paginationParam0 = {
        ...paginationParam,
        currentPage: 1
      }
      loadTreeData(searchingUserId, paginationParam0)
    }
  }, [shouldSearchAgain])

  useEffect(() => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1
    }
    loadTreeData('', paginationParam0)
    dispatch({ type: 'binaryTree.INIT', payload: { tree_type: 'unilevel' } })
  }, [])

  return (
    <Wrapper>
      <TreeView 
        treeData={treeData} 
        isLoading={isLoading} 
        loadRoot={loadRoot}
        loadParent={loadParent}
        search={search}
      />
      <div className={'pagination-root'}>
        <Pagination
          current={paginationParam.currentPage}
          pageSize={paginationParam.perPage}
          total={paginationParam.total}
          itemRender={(page, type) => (
          <>
            {type==='page' ? 
              <span className={`${page*1===paginationParam.currentPage*1?'active':''}`}>
                {page}
              </span>
            : type==='prev' ? 
              <LeftOutlined />
            : type==='next' ? 
              <RightOutlined />
            : '...'
            }
          </>
          )}
          onChange={onPageChange}
          showSizeChanger={false}
          showQuickJumper={false}
          hideOnSinglePage={true}
          showTotal={(total, range) => (
            <span>
              Showing Results {range[0]}-{range[1]} of {total}
            </span>
          )}
        />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
.orgchart-container {
  height: calc(100vh - 165px);
}
.pagination-root {
  padding-top: 10px; 
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-pagination-total-text {
    line-height: 32px;
  }
  .ant-pagination-item {
    border: 0 none;
    background: transparent;
    min-width: 16px;
    span {
      line-height: 32px;
    }
  }
  .anticon {
    line-height: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-pagination-item .active {
    color: ${props=>props.theme.palette.primary};
  }
  .ant-pagination-disabled, .ant-pagination-disabled:hover, .ant-pagination-disabled:focus-visible {
    opacity: 0.8;
  }
}
`
