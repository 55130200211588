import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AuthedLayout from "layouts/authed/AuthedLayout";
import AuthLayout from "layouts/auth/AuthLayout";
import StartedLayout from "layouts/started/StartedLayout";

import LogoutRoute from "views/auth/logout/LogoutRoute";
import LoginPage from "views/auth/login/LoginPage";
import HomePage from "views/home/HomePage";
import GetStartedPage from "views/getStarted/GetStartedPage";
import IdAndCreditVerificationPage from "views/idAndCreditVerification/IdAndCreditVerificationPage";
import TaxVerificationPage from "views/taxVerification/TaxVerificationPage";
import MyContactPage from "views/contact/myContact/MyContactPage";
import TeamContactPage from "views/contact/teamContact/TeamContactPage";
import MtdLeaderboardPage from "views/leaderboard/mtd/MtdLeaderboardPage";
import PrevLeaderboardPage from "views/leaderboard/prev/PrevLeaderboardPage";
import EnrollerTreePage from "views/myTeam/enrollerTree/EnrollerTreePage";
import UplinePage from "views/myTeam/upline/UplinePage";
import MyEarningPage from "views/earning/myEarning/MyEarningPage";
import TeamEarningPage from "views/earning/teamEarning/TeamEarningPage";
import ResourcePage from "views/tool/resource/ResourcePage";
import ToolGetStartedPage from "views/tool/getStarted/GetStartedPage";
import TrainingPage from "views/tool/training/TrainingPage";
import OrderHistoryPage from "views/myAccount/orderHistory/OrderHistoryPage";
import OrderReceiptPage from 'views/myAccount/orderReceipt/OrderReceiptPage';
import SubscriptionPage from "views/myAccount/subscription/SubscriptionPage";
import PaymentOptionPage from "views/myAccount/paymentOption/PaymentOptionPage";
import CreditWalletPage from "views/myAccount/creditWallet/CreditWalletPage";
import CreditCardPage from "views/myAccount/creditCard/CreditCardPage";
import ProfilePage from "views/myAccount/profile/ProfilePage";
import MyProposalPage from "views/proposal/myProposal/MyProposalPage";
import TeamProposalPage from "views/proposal/teamProposal/TeamProposalPage";
import MyProjectPage from "views/project/myProject/MyProjectPage";
import TeamProjectPage from "views/project/teamProject/TeamProjectPage";
import ReplicatedPage from 'views/myWebsite/replicated/ReplicatedPage';
import CapturePage from 'views/myWebsite/capture/CapturePage';

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <AuthRoute path="/login" component={LoginPage} />
        <Route path="/logout" component={LogoutRoute} />
        <AuthedRoute path="/" component={HomePage} fullLayout exact={true} />
        <StartedRoute
          publicRoute={false}
          path="/get_started"
          component={GetStartedPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/verification"
          component={IdAndCreditVerificationPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/tax_form"
          component={TaxVerificationPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/contact/my_contact"
          component={MyContactPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/contact/team_contact"
          component={TeamContactPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/leaderboard/mtd"
          component={MtdLeaderboardPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/leaderboard/prev"
          component={PrevLeaderboardPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_team/enroller_tree"
          component={EnrollerTreePage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_team/upline"
          component={UplinePage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/earning/my_earning"
          component={MyEarningPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/earning/team_earning"
          component={TeamEarningPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/tool/get_started"
          component={ToolGetStartedPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/tool/training"
          component={TrainingPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/tool/resource"
          component={ResourcePage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_account/order_history"
          component={OrderHistoryPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_account/order_receipt"
          component={OrderHistoryPage}
          fullLayout
        />
        <Route
          path="/my_account/order_receipt/:orderId"
          component={OrderReceiptPage}
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_account/subscription"
          component={SubscriptionPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_account/payment_option"
          component={PaymentOptionPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_account/credit_wallet"
          component={CreditWalletPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_account/credit_card"
          component={CreditCardPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_account/profile"
          component={ProfilePage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/proposal/my_proposal"
          component={MyProposalPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/proposal/team_proposal"
          component={TeamProposalPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/project/my_project"
          component={MyProjectPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/project/team_project"
          component={TeamProjectPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_website/replicated"
          component={ReplicatedPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_website/capture"
          component={CapturePage}
          fullLayout
        />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;

const AuthRoute = ({ component: Component, ...props }) => {
  return (
    <Route
      {...props}
      render={(props) => (
        <AuthLayout pathname={props.location.pathname}>
          <Component {...props} />
        </AuthLayout>
      )}
    />
  );
};

const AuthedRoute = ({
  component: Component,
  fullLayout,
  publicRoute,
  ...props
}) => {
  return (
    <Route
      {...props}
      render={(props) => (
        <AuthedLayout
          fullLayout={fullLayout}
          pathname={props.location.pathname}
          publicRoute={publicRoute}
        >
          <Component {...props} />
        </AuthedLayout>
      )}
    />
  );
};

const StartedRoute = ({
  component: Component,
  fullLayout,
  publicRoute,
  ...props
}) => {
  return (
    <Route
      {...props}
      render={(props) => (
        <StartedLayout
          fullLayout={fullLayout}
          pathname={props.location.pathname}
          publicRoute={publicRoute}
        >
          <Component {...props} />
        </StartedLayout>
      )}
    />
  );
};
