import React, { useState } from 'react';
import styled from 'styled-components';
import { Switch } from 'components/common';
import ListSubPage from './list/ListSubPage';
import TreeSubPage from './tree/TreeSubPage';

export default function EnrollerTreePage() {
  const [viewMode, setViewMode] = useState('list');

  return (
    <Wrapper>
      <div className='mode-change-root'>
        <span>
          Tree View
        </span>
        <Switch checked={viewMode==='list'}
          onChange={checked=>setViewMode(checked?'list':'tree')}
        />
        <span>
          List View
        </span>
      </div>
      {viewMode === 'list' && <ListSubPage />}
      {viewMode === 'tree' && <TreeSubPage />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .mode-change-root {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 12px;
    span {
      margin: 0 8px;
    }
  }
`
