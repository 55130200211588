import React from 'react'

export default function StepBar({ step }) {
  return (
    <div className='stepBar'>
      <div className={`stepNode ${step==='billing'?'current':''} ${['shipping', 'gift', 'cc', 'term', 'upload'].indexOf(step)>=0?'past':''}`}>
        <div className='stepTitle'>
          Billing<br/>
          Profile
        </div>
      </div>
      <div className='stepLine' />
      <div className={`stepNode ${step==='shipping'?'current':''} ${['gift', 'cc', 'term', 'upload'].indexOf(step)>=0?'past':''}`}>
        <div className='stepTitle'>
          Shipping<br/>
          Address
        </div>
      </div>
      <div className='stepLine' />
      <div className={`stepNode ${step==='gift'?'current':''} ${['cc', 'term', 'upload'].indexOf(step)>=0?'past':''}`}>
        <div className='stepTitle'>
          Apparel<br/>
          Sizes
        </div>
      </div>
      <div className='stepLine' />
      <div className={`stepNode ${step==='cc'?'current':''} ${['term', 'upload'].indexOf(step)>=0?'past':''}`}>
        <div className='stepTitle'>
          Business<br/>
          Fees
        </div>
      </div>
      <div className='stepLine' />
      <div className={`stepNode ${step==='term'?'current':''} ${['upload'].indexOf(step)>=0?'past':''}`}>
        <div className='stepTitle'>
          Terms&nbsp;&amp;<br/>
          Conditions
        </div>
      </div>
      <div className='stepLine' />
      <div className={`stepNode ${step!=='upload'?'':'current'}`}>
        <div className='stepTitle'>
          Upload<br/>
          Photo
        </div>
      </div>
    </div>
  )
}
