import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import payment from "payment";
import { 
  Row, Col, Modal, 
  Button, Input, Select, FormItem,
  Popconfirm, SuccessNotification, message
} from "components/common";
import CreditCardInput from "react-credit-card-input";
import { countryStates, statesByCountry } from 'common/country'
import { varOptions } from 'common/var'
import { callPostApiWithAuth } from 'utils/api'
import { Wrapper } from "./AddModal.styled";

export default function AddModal(props) {
  const dispatch = useDispatch()
  const myUser = useSelector(state=>state.auth.user)
  const [formData, setFormData] = useState({
    cc_name: '', cc_number: '',
    cc_exp_date: '', cc_cvv: '',
    billing_address: '', billing_address_line2: '',
    billing_city: '', billing_zipcode: '',
    billing_state: '', billing_country: ''
  })
  const [isUpdating, setIsUpdating] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])

  const ccTypeValue = (label) => {
    let value = 0;
    varOptions('userBillingDetail.ccType').forEach((el) => {
      if (el.label.toLowerCase() === label) {
        value = el.value;
      }
    });

    return value;
  };

  const onSubmitDone = (res) => {
    setIsUpdating(false)
    SuccessNotification(res.message)
    dispatch({ type: 'auth.RELOAD' })
    dispatch({ type: 'auth.CHECK_HOLD' })
    props.setModalMode(undefined)
  }
  const onSubmitFail = () => {
    setIsUpdating(false)
    dispatch({ type: 'auth.CHECK_HOLD' })
  }
  const handleSubmit = () => {
    // Validation
    const regex = /^[\u4e00-\u9fa5_A-Za-z0-9\s!@#$%^&*()_+=\-`~\\\]\[{}|';:/.,?><]*$/;
    let errorMessages0 = []
    if (!formData.cc_name) {
      errorMessages0.push({
        field: 'cc_name',
        message: 'Required'
      })
    } else if (regex.exec(formData.cc_name) == null) {
      errorMessages0.push({
        field: 'cc_name',
        message: 'Please input only English'
      })
    }
    if (!formData.cc_number || 
      !formData.cc_exp_date || 
      !formData.cc_cvv
    ) {
      errorMessages0.push({
        field: 'cc_number',
        message: 'Required'
      })
    }
    if (!formData.billing_address) {
      errorMessages0.push({
        field: 'billing_address',
        message: 'Required'
      })
    } else if (regex.exec(formData.billing_address) == null) {
      errorMessages0.push({
        field: 'billing_address',
        message: 'Please input only English'
      })
    }
    if (formData.billing_address_line2 && 
      regex.exec(formData.billing_address_line2) == null
    ) {
      errorMessages0.push({
        field: 'billing_address_line2',
        message: 'Please input only English'
      })
    }
    if (!formData.billing_city) {
      errorMessages0.push({
        field: 'billing_city',
        message: 'Required'
      })
    } else if (regex.exec(formData.billing_city) == null) {
      errorMessages0.push({
        field: 'billing_city',
        message: 'Please input only English'
      })
    }
    if (!formData.billing_zipcode) {
      errorMessages0.push({
        field: 'billing_zipcode',
        message: 'Required'
      })
    }
    if (!formData.billing_state && 
      statesByCountry(formData.billing_country).length>0
    ) {
      errorMessages0.push({
        field: 'billing_state',
        message: 'Required'
      })
    }
    if (!formData.billing_country) {
      errorMessages0.push({
        field: 'billing_country',
        message: 'Required'
      })
    }
    setErrorMessages(errorMessages0)

    if (errorMessages0.length>0) {
      return
    }

    setIsUpdating(true)
    let body = {...formData}
    body['cc_number'] = body.cc_number.replace(/\s/g, '')
    body['cc_exp_date'] = body.cc_exp_date.replace(/\s/g, '')
    callPostApiWithAuth(`my_account/credit_card/create`, body,
      onSubmitDone, onSubmitFail
    )
  }

  useEffect(() => {
    setFormData({
      ...formData,
      billing_country: myUser.country
    })
  }, [myUser])

  return (
    <Modal width={600}
      onCancel={()=>props.setModalMode(undefined)}
    >
      <Wrapper>
        <div className="modal-title-container">Add Credit Card</div>
        <Row gutter={[20, 20]}>
          <Col xs={24}>
            <div className="input-container">
              <FormItem label={"Name on Card*"}
                errorMessages={errorMessages.filter(el=>el.field === 'cc_name')}
              >
                <Input placeholder="Enter Name on Card" 
                  value={formData.cc_name}
                  onChange={e=>setFormData({...formData, cc_name:e.target.value})}
                />
              </FormItem>
            </div>
          </Col>{" "}
          <Col xs={24}>
            <div className="input-container credit-input-box">
              <FormItem label={"Card Number"}
                errorMessages={errorMessages.filter(el=>
                  el.field === 'cc_number' 
                )}
              >
                <CreditCardInput
                  cardNumberInputProps={{
                    value: formData.cc_number,
                    onChange: (e) =>
                      setFormData({
                        ...formData,
                        cc_number: e.target.value,
                        cc_type: ccTypeValue(
                          payment.fns.cardType(e.target.value)
                        ),
                      }),
                  }}
                  cardExpiryInputProps={{
                    value: formData.cc_exp_date,
                    onChange: (e) => {
                      setFormData({
                        ...formData,
                        cc_exp_date: e.target.value,
                      });
                    },
                  }}
                  cardCVCInputProps={{
                    value: formData.cc_cvv,
                    onChange: (e) =>
                      setFormData({
                        ...formData,
                        cc_cvv: e.target.value,
                      }),
                  }}
                  fieldClassName={("input", "credit-input-container")}
                />
              </FormItem>
            </div>
          </Col>{" "}
          <Col xs={24} sm={12}>
            <div className="input-container">
              <FormItem label={"Address Line 1*"}
                errorMessages={errorMessages.filter(el=>el.field === 'billing_address')}
              >
                <Input placeholder="Enter Address Line 1" 
                  value={formData.billing_address}
                  onChange={e=>setFormData({...formData, billing_address:e.target.value})}
                />
              </FormItem>
            </div>
          </Col>{" "}
          <Col xs={24} sm={12}>
            <div className="input-container">
              <FormItem label={"Address Line 2"}
                errorMessages={errorMessages.filter(el=>el.field === 'billing_address_line2')}
              >
                <Input placeholder="Enter Address Line 2" 
                  value={formData.billing_address_line2}
                  onChange={e=>setFormData({...formData, billing_address_line2:e.target.value})}
                />
              </FormItem>
            </div>
          </Col>{" "}
          <Col xs={24} sm={12}>
            <div className="input-container">
              <FormItem label={"City*"}
                errorMessages={errorMessages.filter(el=>el.field === 'billing_city')}
              >
                <Input placeholder="Enter City" 
                  value={formData.billing_city}
                  onChange={e=>setFormData({...formData, billing_city:e.target.value})}
                />
              </FormItem>
            </div>
          </Col>{" "}
          <Col xs={24} sm={12}>
            <div className="input-container">
              <FormItem label={"Zip/Postal code*"}
                errorMessages={errorMessages.filter(el=>el.field === 'billing_zipcode')}
              >
                <Input placeholder="Enter Zip/Postal code"
                  value={formData.billing_zipcode}
                  onChange={e=>setFormData({...formData, billing_zipcode:e.target.value})}
                />
              </FormItem>
            </div>
          </Col>{" "}
          <Col xs={24} sm={12}>
            <div className="input-container">
              <FormItem label={"Country*"}
                errorMessages={errorMessages.filter(el=>el.field === 'billing_country')}
              >
                <Select
                  value={formData.billing_country}
                  options={[
                    { 
                      label: 'Select Country', 
                      value: ''
                    },
                    ...countryStates.map(el => ({
                      label: el.name,
                      value: el.code2
                    }))
                  ]}
                  style={{ width: "100%" }}
                  onChange={v=>setFormData({...formData, billing_country:v})}
                />
              </FormItem>
            </div>
          </Col>{" "}
          <Col xs={24} sm={12}>
            {statesByCountry(formData.billing_country).length>0?
            <div className="input-container">
              <FormItem label={"State/Province*"}
                errorMessages={errorMessages.filter(el=>el.field === 'billing_state')}
              >
                <Select
                  value={formData.billing_state}
                  options={[
                    { 
                      label: 'Select State', 
                      value: ''
                    },
                    ...statesByCountry(formData.billing_country).map(el => ({
                      label: el.name,
                      value: el.name
                    }))
                  ]}
                  style={{ width: "100%" }}
                  onChange={v=>setFormData({...formData, billing_state:v})}
                  showSearch
                />
              </FormItem>
            </div>
            : ''}
          </Col>{" "}
          <Col span={24}>
            <div className="action-btn">
              <Popconfirm onConfirm={handleSubmit}>
                <Button loading={isUpdating}>
                  Create
                </Button>
              </Popconfirm>
            </div>
          </Col>
        </Row>
      </Wrapper>

    </Modal>
  );
}
