import React, { useEffect, useState } from 'react';
import {
  Input, Select, Checkbox, Button,
} from 'components/common';
import { countryStates, statesByCountry } from 'common/country';

export default function ShippingForm(props) {
  const [formData, setFormData] = useState(props.shippingDetail)
  const [isSameAddr, setIsSameAddr] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])

  const onFormInput = (field, value) => {
    setFormData({
      ...formData,
      [field]: value
    })
  }

  const handleContinue = () => {
    let errorMessages0 = [];
    [
      'shipping_address', 'shipping_zipcode',
      'shipping_state', 'shipping_city', 'shipping_country'
    ].forEach(k => {
      if (!formData[k]) {
        errorMessages0.push({
          field: k,
          message: 'Required'
        })
      }
    })
    setErrorMessages(errorMessages0)
    if (errorMessages0.length>0) return;

    props.setShippingDetail({
      ...props.shippingDetail,
      ...formData,
    })
    props.setStep('gift')
  }

  useEffect(() => {
    if (props.shippingDetail) {
      setFormData({
        ...formData,
        ...props.shippingDetail,
      })
    }
  }, [props.shippingDetail])

  useEffect(() => {
    if (isSameAddr) {
      setFormData({
        ...formData,
        shipping_address: props.billingDetail.billing_address,
        shipping_city: props.billingDetail.billing_city,
        shipping_zipcode: props.billingDetail.billing_zipcode,
        shipping_state: props.billingDetail.billing_state,
        shipping_country: props.billingDetail.billing_country,
      })
    }
  }, [isSameAddr])

  return (
    <>
      <div className='form-card'>
        <div className='form-group'>
          <Checkbox checked={isSameAddr}
            onChange={e=>setIsSameAddr(e.target.checked)}
            label={'Shipping is the same as Billing profile.'}
          />
        </div>
        <div className='form-group'>
          <Input value={formData.shipping_address}
            onChange={e=>onFormInput('shipping_address', e.target.value)}
            placeholder={'Lot, Street and Apt #'}
          />
          {errorMessages.filter(el=>el.field==='shipping_address')
          .map(el => (
            <div className='form-error'>
              {el.message}
            </div>
          ))}
        </div>
        <div className='form-group'>
          <Input value={formData.shipping_city}
            onChange={e=>onFormInput('shipping_city', e.target.value)}
            placeholder={'City'}
          />
          {errorMessages.filter(el=>el.field==='shipping_city')
          .map(el => (
            <div className='form-error'>
              {el.message}
            </div>
          ))}
        </div>
        <div className='form-group'>
          <Select value={formData.shipping_country}
            onChange={v=>onFormInput('shipping_country', v)}
            options={[
              { value: '', label: 'Country' },
              ...countryStates.map(el => ({
                value: el.code2, label: el.name
              }))
            ]}
          />
          {errorMessages.filter(el=>el.field==='shipping_country')
          .map(el => (
            <div className='form-error'>
              {el.message}
            </div>
          ))}
        </div>
        <div className='form-group'>
          <Select value={formData.shipping_state}
            onChange={v=>onFormInput('shipping_state', v)}
            options={[
              { value: '', label: 'State' },
              ...statesByCountry(formData.shipping_country).map(el => ({
                value: el.name, label: el.name,
              }))
            ]}
          />
          {errorMessages.filter(el=>el.field==='shipping_state')
          .map(el => (
            <div className='form-error'>
              {el.message}
            </div>
          ))}
        </div>
        <div className='form-group'>
          <Input value={formData.shipping_zipcode}
            onChange={e=>onFormInput('shipping_zipcode', e.target.value)}
            placeholder={'Postal Code'}
          />
          {errorMessages.filter(el=>el.field==='shipping_zipcode')
          .map(el => (
            <div className='form-error'>
              {el.message}
            </div>
          ))}
        </div>
        <div className='action-group'>
          <div>
            <Button onClick={()=>props.setStep('billing')}>
              Previous
            </Button>
          </div>
          <div>
            <Button onClick={handleContinue}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
