import { createReducer } from "redux-create-reducer";

const initialState = {
  theme: {
    palette: {
      primary: "#00b2ff",
      secondary: "#384971",
      text: "rgba(0, 0, 0, 0.85)",
      secondaryText: '#7f8fa4',
      link: '#1890ff',
      pending: "#00b2ff",
      success: "#52c41a",
      warning: "#faad14",
      error: "#ff4d4f",
      menuBg: '#fff',
      menuText: 'rgba(0, 0, 0, 0.85)',
    },
  }
};

export default createReducer(initialState, {
  [`systemSetting.SET_THEME`](state, action) {
    return {
      ...state,
      theme: action.payload.theme,
    };
  },
});
