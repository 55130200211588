import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RingProgress } from '@ant-design/charts';
import {
  callGetApiWithAuth
} from 'utils/api';
import { asKNumber, asPrice } from 'utils/text';
import {
  Table
} from 'components/common'
import BannerImg from 'assets/banner.png';
import StatCardImg from 'assets/stat-card.png';
import StatCardActiveImg from 'assets/stat-card-active.png';
import CheckedImg from 'assets/checked.png';
import NoPhotoIcon from 'assets/user.svg';
import { BsArrowRight } from 'react-icons/bs';
import Train1Img from 'assets/train1.png';
import Train2Img from 'assets/train2.png';
import Train3Img from 'assets/train3.png';
import Train4Img from 'assets/train4.png';

export default function HomePage() {
  const myUser = useSelector(state=>state.auth.user)
  const [poolStat, setPoolStat] = useState({
    pro: { amount: 0, percent: 0 },
    dir: { amount: 0, percent: 0 },
    vp: { amount: 0, percent: 0 },
    svp: { amount: 0, percent: 0 }
  })
  const [leadStat, setLeadStat] = useState({
    my_new: 0,
    team_new: 0,
    my_worked: 0,
    team_worked: 0,
    my_last: 0,
    team_last: 0,
  })
  const [projectStat, setProjectStat] = useState({
    my_active: 0,
    team_active: 0,
    my_hold: 0,
    team_hold: 0,
    my_pending: 0,
    team_pending: 0,
  })
  const [saleList, setSaleList] = useState({
    prev: [], mtd: [],
  })
  const [isLoading, setIsLoading] = useState(false)
  const [period, setPeriod] = useState('mtd')
  const [accountStat, setAccountStat] = useState({
    earning: 0, 
    pending: 0,
    wallet: 0, 
    withdrawn: 0,
  })
  const [isAccLoading, setIsAccLoading] = useState(false)

  const onGetPoolStat = ({ data }) => {
    setPoolStat(data.pool)
    setLeadStat(data.lead)
    setProjectStat(data.project)
    setIsLoading(false)
  }
  const onFailPoolStat = () => {
    setIsLoading(false)
  }
  const getPoolStat = () => {
    setIsLoading(true)
    callGetApiWithAuth(`home/pool_stat`, onGetPoolStat, onFailPoolStat)
  }

  const onGetSales = ({ data }) => {
    setSaleList(data)
  }
  const onFailSales = () => {}
  const getSales = () => {
    callGetApiWithAuth(`home/sales`, onGetSales, onFailSales)
  }

  const onGetAccountStat = ({ data }) => {
    setAccountStat(data)
    setIsAccLoading(false)
  }
  const onFailAccountStat = () => {
    setIsAccLoading(false)
  }
  const getAccountStat = () => {
    setIsAccLoading(true)
    callGetApiWithAuth(`home/account_stat?period=${period}`, onGetAccountStat, onFailAccountStat)
  }

  useEffect(() => {
    getPoolStat();
    getSales();
  }, [])

  useEffect(() => {
    getAccountStat();
  }, [period])

  return (
    <Wrapper>
      <div className='banner-root'>
        <img src={BannerImg} />
        <div className='stat-root'>
          <div className={`stat-card ${poolStat.pro.percent>=100?'active':''}`}>
            <div className={`stat-check`} />
            <div className='stat-amount'>
              ${asKNumber(poolStat.pro.amount)}
            </div>
            <div className='stat-desc'>
              Pro Pool
            </div>
          </div>
          <div className={`stat-card ${poolStat.dir.percent>=100?'active':''}`}>
            <div className={`stat-check`} />
            <div className='stat-amount'>
              ${asKNumber(poolStat.dir.amount)}
            </div>
            <div className='stat-desc'>
              Director Pool
            </div>
          </div>
          <div className={`stat-card ${poolStat.vp.percent>=100?'active':''}`}>
            <div className={`stat-check`} />
            <div className='stat-amount'>
              ${asKNumber(poolStat.vp.amount)}
            </div>
            <div className='stat-desc'>
              VP Pool
            </div>
          </div>
          <div className={`stat-card ${poolStat.svp.percent>=100?'active':''}`}>
            <div className={`stat-check`} />
            <div className='stat-amount'>
              ${asKNumber(poolStat.svp.amount)}
            </div>
            <div className='stat-desc'>
              Senior VP Pool
            </div>
          </div>
        </div>
      </div>
      <div className='main-root'>
        <div className='ml-root'>
          <div className='st-table'>
            <Table
              pagination={false}
              columns={[
                {
                  title: 'Leads',
                  key: 'lead',
                  render: (_, __, ind) => (
                    <span>
                      {ind===0?'New Lead'
                      : ind===1?'Worked Lead'
                      : 'Lost Lead'}
                    </span>
                  )
                },
                {
                  title: 'My Leads',
                  key: 'my',
                  render: (_, __, ind) => (
                    <span>
                      {ind===0?leadStat.my_new
                      : ind===1?leadStat.my_worked
                      : leadStat.my_last}
                    </span>
                  )
                },
                {
                  title: 'Team',
                  key: 'team',
                  render: (_, __, ind) => (
                    <span>
                      {ind===0?leadStat.team_new
                      : ind===1?leadStat.team_worked
                      : leadStat.team_last}
                    </span>
                  )
                },
              ]}
              dataSource={['','','']}
            />
          </div>
          <div style={{ height: 24 }} />
          <div className='st-table'>
            <Table
              pagination={false}
              columns={[
                {
                  title: 'Projects',
                  key: 'project',
                  render: (_, __, ind) => (
                    <span>
                      {ind===0?'Active'
                      : ind===1?'On Lead'
                      : 'Pending for Cancelation'}
                    </span>
                  )
                },
                {
                  title: 'My Projects',
                  key: 'my',
                  render: (_, __, ind) => (
                    <span>
                      {ind===0?projectStat.my_active
                      : ind===1?projectStat.my_hold
                      : projectStat.my_pending}
                    </span>
                  )
                },
                {
                  title: 'Team',
                  key: 'team',
                  render: (_, __, ind) => (
                    <span>
                      {ind===0?projectStat.team_active
                      : ind===1?projectStat.team_hold
                      : projectStat.team_pending}
                    </span>
                  )
                },
              ]}
              dataSource={['','','']}
            />
          </div>
          <div style={{ height: 24 }} />
          <div className='st-table'>
            <div className='st-sub-table'>
              <h4>Previous Day Sales</h4>
              <Table
                showHeader={false}
                pagination={false}
                columns={[
                  {
                    title: 'Name',
                    key: 'name',
                    render: (_, record) => (
                      <span>
                        {record.user?
                          record.user.first_name+' '+record.user.last_name
                        : ''}
                      </span>
                    )
                  },
                  {
                    title: 'Amount',
                    key: 'amount',
                    render: (_, record) => (
                      <span className='sr-amount'>
                        {asPrice(record.amount)}
                      </span>
                    )
                  },
                ]}
                dataSource={saleList.prev}
              />
            </div>
            <div className='st-sub-table'>
              <h4>My MTD Sales</h4>
              <Table
                showHeader={false}
                pagination={false}
                columns={[
                  {
                    title: 'Name',
                    key: 'name',
                    render: (_, record) => (
                      <span>
                        {record.user?
                          record.user.first_name+' '+record.user.last_name
                        : ''}
                      </span>
                    )
                  },
                  {
                    title: 'Amount',
                    key: 'amount',
                    render: (_, record) => (
                      <span className='sr-amount'>
                        {asPrice(record.amount)}
                      </span>
                    )
                  },
                ]}
                dataSource={saleList.mtd}
              />
            </div>
          </div>
        </div>
        <div className='ql-root'>
          <h4>Qualified</h4>
          <div className='ql-chart'>
            <PieChart
              value={poolStat.pro.percent}
              fill={poolStat.pro.percent>=100?'#31D855':'#FA8620'}
            />
            <p>Pro Pool</p>
          </div>
          <div className='ql-chart'>
            <PieChart
              value={poolStat.dir.percent}
              fill={poolStat.dir.percent>=100?'#31D855':'#FA8620'}
            />
            <p>Director Pool</p>
          </div>
          <div className='ql-chart'>
            <PieChart
              value={poolStat.vp.percent}
              fill={poolStat.vp.percent>=100?'#31D855':'#FA8620'}
            />
            <p>Vice President Pool</p>
          </div>
          <div className='ql-chart'>
            <PieChart
              value={poolStat.svp.percent}
              fill={poolStat.svp.percent>=100?'#31D855':'#FA8620'}
            />
            <p>Senior Vice President Pool</p>
          </div>
        </div>
        <div className='account-card'>
          <div className='acc-photo'>
            <img src={myUser?.image || NoPhotoIcon} />
          </div>
          <div className='acc-username'>
            {myUser?.first_name+' '+myUser?.last_name}
            {' | '}
            {myUser?.uuid}
          </div>
          <div className='rank-span'>
            {''}
          </div>
          <div className='period-group'>
            <div className={`period-span ${period==='mtd'?'active':''}`}
              onClick={() => setPeriod('mtd')}
            >
              MTD
            </div>
            <span className='border-line' />
            <div className={`period-span ${period==='ytd'?'active':''}`}
              onClick={() => setPeriod('ytd')}
            >
              YTD
            </div>
            <span className='border-line' />
            <div className={`period-span ${period==='lifetime'?'active':''}`}
              onClick={() => setPeriod('lifetime')}
            >
              Carrer
            </div>
          </div>
          <div className='acc-rows'>
            <div className='acc-row'>
              <span>Earnings:</span>
              <span>{asPrice(accountStat.earning)}</span>
            </div>
            <div className='acc-row'>
              <span>Pending:</span>
              <span>{asPrice(accountStat.pending)}</span>
            </div>
            <div className='acc-row'>
              <span>Wallet Balance:</span>
              <span>{asPrice(accountStat.wallet)}</span>
            </div>
            <div className='acc-row'>
              <span>Withdrawn:</span>
              <span>{asPrice(accountStat.withdrawn)}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='btm-root'>
        <div className='train-card'>
          <div className='card-img'>
            <img src={Train1Img} />
          </div>
          <div className='card-content'>
            <h4>Leaderboard</h4>
            <p>
              See who's leading the charge in solar installations! Check out our real-time leaderboard to find out who's making the biggest impact.
            </p>
            <div className='card-link'>
              VIEW LEADERBOARD&nbsp;
              <BsArrowRight />
            </div>
          </div>
        </div>
        <div className='train-card'>
          <div className='card-img'>
            <img src={Train2Img} />
          </div>
          <div className='card-content'>
            <h4>Calls, Blitzes & Events</h4>
            <p>
              Don't miss out on our upcoming events! Help accelerate our mission to put solar on every rooftop in America by teaming up with top leaders.
            </p>
            <div className='card-link'>
              VIEW EVENTS&nbsp;
              <BsArrowRight />
            </div>
          </div>
        </div>
        <div className='train-card'>
          <div className='card-img'>
            <img src={Train3Img} />
          </div>
          <div className='card-content'>
            <h4>Training</h4>
            <p>
              Get the skills you need to succeed as a Solar Specialist! Our training modules are designed to help you become an expert in solar energy.
            </p>
            <div className='card-link'>
              VIEW TRAINING&nbsp;
              <BsArrowRight />
            </div>
          </div>
        </div>
        <div className='train-card'>
          <div className='card-img'>
            <img src={Train4Img} />
          </div>
          <div className='card-content'>
            <h4>Shop Kumquat</h4>
            <p>
              Shop for exclusive gear, apparel, and our healthy energy drink to power up your day and represent in style.
            </p>
            <div className='card-link'
              onClick={()=>window.open('http://kumquatswag.com', '_blank')}
            >
              SHOP MERCH&nbsp;
              <BsArrowRight />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const PieChart = (props) => {
  var config = {
    autoFit: false,
    percent: props.value / 100,
    color: [props.fill, '#E8EDF3'],
    height: 100,
    width: 100,
    innerRadius: 0.6,
    radius: 0.98,
    statistic: {
      title: {
        style: {
          fontSize: '15px',
          fontWeight: 'bold',
        },
        formatter: function formatter() {
          // eslint-disable-next-line prefer-template
          return props.value + '%';
        },
      },
      content: false,
    },
  };

  return <RingProgress {...config} />;
};

const Wrapper = styled.div`
  .banner-root {
    width: 100%;
    margin-bottom: 24px;
    position: relative;
    img {
      width: 100%;
    }
    .stat-root {
      margin-right: 20px;
      position: absolute;
      left: 0; right: 0;
      top: 0; bottom: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @media (max-width: 768px) {
        display: none;
      }
      .stat-card {
        width: 130px;
        height: 130px;
        margin: 5px;
        background-image: url('${StatCardImg}');
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        &.active {
          background-image: url('${StatCardActiveImg}');
          .stat-check {
            background-image: url('${CheckedImg}');
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;
          }
          .stat-amount {
            color: #30D855;
          }
        }
        .stat-check {
          width: 21px; height: 16px;
        }
        .stat-amount {
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
          color: #F98922;
        }
        .stat-desc {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #31394D;
          opacity: 0.7;
        }
      }
    }
  }
  .main-root {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .ml-root {
      width: 50%;
      @media (max-width: 1366px) {
        width: 100%;
      }
      .st-table {
        background: #FFFFFF;
        border: 1px solid #E8E8E8;
        border-radius: 5px;
        display: flex;
        flex-wrap: wrap;
        .ant-table-wrapper {
          width: 100%;
        }
        th {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #31394D;
          padding-top: 12px;
          background-color: transparent;
        }
      }
      .st-sub-table {
        width: 50%;
        @media (max-width: 768px) {
          width: 100%;
        }
        h4 {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #31394D;
          padding-left: 16px;
          padding-top: 12px;
        }
        .sr-amount {
          color: #FA8620;
        }
      }
    }
    .ql-root {
      width: 260px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #FFFFFF;
      border: 1px solid #E8E8E8;
      border-radius: 5px;
      @media (max-width: 768px) {
        width: 100%;
      }
      @media (max-width: 1366px) {
        margin-top: 24px;
      }
      h4 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #31394D;
        padding: 11px 0;
        border-bottom: 1px solid #E8E8E8;
        width: 100%;
        text-align: center;
      }
      .ql-chart {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          text-align: center;
        }
      }
    }
    .account-card {
      width: calc(50% - 300px);
      @media (max-width: 1366px) {
        width: calc(100% - 300px);
        margin-top: 24px;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
  .account-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(145.16deg, #F79025 -4.97%, #FE6C11 68.31%);
    border-radius: 10px;
    .acc-photo {
      width: 232px;
      height: 136px;
      border-radius: 16px;
      overflow: hidden;
      margin-top: 24px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .acc-username {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #FFFFFF;
      margin-top: 13px;
      margin-bottom: 4px;
    }
    .rank-span {
      color: #fff;
      margin-bottom: 20px;
    }
    .period-group {
      border: 1px solid #FFFFFF;
      border-radius: 4px;
      display: flex;
      opacity: 0.8;
      cursor: pointer;
      .period-span {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #FFFFFF;
        padding: 7px 10px;
        &.active {
          color: #FA8620;
          background-color: #fff;
        }
      }
      .border-line {
        width: 1px;
        height: 100%;
        background: #fff;
      }
    }
    .acc-rows {
      margin-top: 20px;
      width: 100%;
      padding: 0 20px;
      .acc-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        span {
          width: 50%;
          padding: 10px 0;
          color: #fff;
        }
        span:nth-child(2) {
          text-align: right;
        }
      }
    }
  }
  .btm-root {
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .train-card {
      width: calc(25% - 18px);
      background: #FFFFFF;
      border-radius: 5px;
      margin-bottom: 24px;
      @media (max-width: 1366px) {
        width: calc(50% - 12px);
      }
      @media (max-width: 768px) {
        width: 100%;
      }
      .card-img {
        img {
          width: 100%;
        }
      }
      .card-content {
        padding: 15px;
        h4 {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #31394D;
        }
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #919191;
        }
        .card-link {
          cursor: pointer;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: #FE6C11;
          display: flex;
          align-items: center;
        }
      }
    }
  }
`
