import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  
  Row, Col, Select,
  Button, InputNumber,
  Divider, Input, Popconfirm, 
  WarningNotification,
  SuccessNotification,
  Alert
} from "components/common";
import UserSearchForm from "./UserSearchForm";
import { Wrapper } from "./CreateOrderSubPage.styled";
import { countryStates, statesByCountry } from "common/country";
import { callGetApiWithAuth, callPostApiWithAuth } from "utils/api";
import { varIs, varValue } from "common/var";

export default function CreateOrderSubPage() {
  const dispatch = useDispatch();
  const myUser = useSelector((state) => state.auth.user);
  const orderDetails = useSelector((state) => state.checkout.orderDetails);
  const [isEnoughCW, setIsEnoughCW] = useState(false);
  const [states, setStates] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedUserInfo, setSelectedUserInfo] = useState(undefined);
  const [shippingInfo, setShippingInfo] = useState({
    id: "",
    shipping_address: "",
    shipping_address_line2: "",
    shipping_city: "",
    shipping_zipcode: "",
    shipping_state: "",
    shipping_country: ""
  })
  const [selectedProduct, setSelectedProduct] = useState(undefined);
  const [quantity, setQuantity] = useState(1);
  const [isPurchasing, setIsPurchasing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [errors, setErrors] = useState({
    user_error: "",
    shipping_id_error: "",
    product_error: "",
    shipping_address_error: "",
    shipping_address_line2_error: "",
    shipping_city_error: "",
    shipping_zipcode_error: "",
    shipping_country_error: ""
  });

  const onChangeShippingInfo = (field, value) => {
    setShippingInfo({
      ...shippingInfo,
      [field]: value
    });
  };

  const handleChangeQuantity = (action) => {
    let quantity0 = quantity;

    if (action === "plus") {
      quantity0 ++;
    } else if (action === "minus" && quantity0 > 1) {
      quantity0 --;
    }

    setQuantity(quantity0)
  }

  const addCartValidation = () => {
    let errors0 = {
      user_error: "",
      product_error: ""
    };

    if (!selectedProduct) {
      errors0.product_error = "Please select product";
    }

    if (!selectedUserInfo) {
      errors0.user_error = "Please select user";
    }

    setErrors({
      ...errors,
      ...errors0
    });

    if (errors0.product_error || errors0.user_error) {
      return false;
    }
    
    return true;
  }

  const handleAddToCart = () => {
    if (!addCartValidation()) return;

    const productDetail = products.filter((el) => el.id*1 === selectedProduct*1)[0]

    dispatch({
      type: 'checkout.SET_SHIPPING_INFO',
      payload: {
        shippingInfo
      }
    })

    dispatch({
      type: 'checkout.ADD_CART',
      payload: {
        product: productDetail,
        quantity: quantity,
        pay_cycle: 0,
        contained_products: (varIs("product.isPack", productDetail.is_pack, "yes") && productDetail.product_pack && productDetail.product_pack.pack_contained_products && productDetail.product_pack.pack_contained_products.length !== 0) ? productDetail.product_pack.pack_contained_products.map((el) => ({
          ...el,
          quantity: el.is_fixed==1 ? el.fixed_quantity : el.min_quantity
        })) : []
      }
    });
  }

  const shippingInfoValidate = () => {
    let errors0 = {
      user_error: "",
      shipping_address_error: "",
      shipping_address_line2_error: "",
      shipping_city_error: "",
      shipping_zipcode_error: "",
      shipping_country_error: ""
    };
    const englishRegex = /^[\u4e00-\u9fa5_A-Za-z0-9\s!@#$%^&*()_+=\-`~\\\]\[{}|';:/.,?><]*$/;

    if (!selectedUserInfo) {
      errors0.user_error = "Please select user";
    }

    if (!shippingInfo.shipping_address.trim()) {
      errors0.shipping_address_error = "Please enter shipping address";
    } else if (shippingInfo.shipping_address && englishRegex.exec(shippingInfo.shipping_address) == null) {
      errors0.shipping_address_error = "Please enter only English"
    }

    if (shippingInfo.shipping_address_line2 && englishRegex.exec(shippingInfo.shipping_address_line2) == null) {
      errors0.shipping_address_line2_error = "Please enter only English"
    }

    if (!shippingInfo.shipping_city.trim()) {
      errors0.shipping_city_error = "Please enter city";
    } else if (shippingInfo.shipping_city && englishRegex.exec(shippingInfo.shipping_city) == null) {
      errors0.shipping_city_error = "Please enter only English"
    }

    if (!shippingInfo.shipping_zipcode.trim()) {
      errors0.shipping_zipcode_error = "Please enter zip/postal code";
    }

    if (!shippingInfo.shipping_country) {
      errors0.shipping_country_error = "Please select country";
    }

    setErrors({
      ...errors,
      ...errors0
    });

    if (
      errors0.user_error ||
      errors0.shipping_address_error ||
      errors0.shipping_address_line2_error ||
      errors0.shipping_city_error ||
      errors0.shipping_zipcode_error ||
      errors0.shipping_country_error
    ) {
      return false;
    }

    return true;
  }

  const onSuccessUpdateShippingInfo = ({ data }) => {
    setIsUpdating(false);
    setIsUpdated(true);
    SuccessNotification("Shipping address has been uploaded successfully.");
    setShippingInfo({
      id: data.id,
      shipping_address: data.shipping_address,
      shipping_address_line2: data.shipping_address_line2,
      shipping_city: data.shipping_city,
      shipping_zipcode: data.shipping_zipcode,
      shipping_state: data.shipping_state,
      shipping_country: data.shipping_country
    });
  }

  const onFailedUpdateShippingInfo = (err) => {
    setIsUpdated(false);
    setIsUpdating(false);
  }

  const handleUpdateShipping = () => {
    if (!shippingInfoValidate()) return;

    const data = {
      user: {
        id: selectedUserInfo.id
      },
      shippingDetail: {
        shipping_address: shippingInfo.shipping_address,
        shipping_address_line2: shippingInfo.shipping_address_line2,
        shipping_city: shippingInfo.shipping_city,
        shipping_zipcode: shippingInfo.shipping_zipcode,
        shipping_state: shippingInfo.shipping_state,
        shipping_country: shippingInfo.shipping_country
      }
    }

    setIsUpdating(true);
    callPostApiWithAuth("create_other_order/update_member_shipping", data, onSuccessUpdateShippingInfo, onFailedUpdateShippingInfo);
  }

  const checkoutValidate = () => {

    if (!orderDetails || (orderDetails && orderDetails.length === 0)) {
      WarningNotification("Please add products to cart");
      return false;
    }

    if (!selectedUserInfo) {
      WarningNotification("Please select user");
      return false;
    }

    if (!shippingInfo.id) {
      WarningNotification("Please enter shipping address");
      return false;
    }
    
    setErrors({
      user_error: "",
      shipping_id_error: "",
      product_error: "",
      shipping_address_error: "",
      shipping_address_line2_error: "",
      shipping_city_error: "",
      shipping_zipcode_error: "",
      shipping_country_error: ""
    });

    return true;
  }

  const onSuccessCreatedOrder = () => {
    setIsPurchasing(false);
    SuccessNotification("Order has been created successfully.");
    dispatch({
      type: 'checkout.CLEAR_CART'
    });
    dispatch({
      type: 'auth.RELOAD'
    });
  }

  const onFailedCreateOrder = (err) => {
    setIsPurchasing(false);
  }

  const handlePurchase = () => {
    if (!checkoutValidate()) return;
    
    const data = {
      dist_center_id: "",
      orderFrom: varValue("order.orderFrom", "create_member_order"),
      user: {
        id: selectedUserInfo.id,
      },
      shippingDetail: {
        id: shippingInfo.id
      },
      orderDetails: orderDetails.map((el) => ({
        ...el,
        product_id: el.product.id
      }))
    }

    setIsPurchasing(true);
    callPostApiWithAuth("create_other_order", data, onSuccessCreatedOrder, onFailedCreateOrder);
  }

  const onSuccessGetProducts = ({ data }) => {
    setProducts(data);
  };

  const onGetFailedProducts = (err) => {
    setProducts([]);
  };

  const loadProducts = () => {
    const filter = `?country=${selectedUserInfo.country}&user_type=${selectedUserInfo.type}`;
    callGetApiWithAuth(`create_member/get_products/${filter}`, onSuccessGetProducts, onGetFailedProducts);
  };

  useEffect(() => {
    let errors0 = errors;

    if (selectedUserInfo) {
      errors0 = {
        ...errors0,
        user_error: ""
      }

      if (selectedUserInfo.country && selectedUserInfo.type) {
        loadProducts();
        setStates(statesByCountry(selectedUserInfo.country));
      }
      if (selectedUserInfo.shipping_detail) {
        setIsUpdated(true);
        setShippingInfo(selectedUserInfo.shipping_detail);
        errors0 = {
          ...errors0,
          shipping_id_error: "",
          shipping_address_error: "",
          shipping_address_line2_error: "",
          shipping_city_error: "",
          shipping_zipcode_error: "",
          shipping_country_error: ""
        }
      } else {
        setIsUpdated(false);
        setShippingInfo({
          id: "",
          shipping_address: "",
          shipping_address_line2: "",
          shipping_city: "",
          shipping_zipcode: "",
          shipping_state: "",
          shipping_country: selectedUserInfo.country
        });
      }
    }

    setErrors(errors0);
  }, [selectedUserInfo]);

  useEffect(() => {
    if (myUser) {
      if (myUser.wallet && myUser.wallet.current_balance*1 > 0) {
        setIsEnoughCW(true);
      } else {
        setIsEnoughCW(false);
      }
    }
  }, [myUser]);

  useEffect(() => {
    dispatch({
      type: "checkout.CLEAR_CART"
    });
    dispatch({
      type: "checkout.CLEAR_SHIPPING_INFO"
    });
  }, []);

  return (
    <Wrapper>
      {!isEnoughCW &&
        <div className="wallet-alert">
          <Alert type="error" message="Your current wallet is not enough." showIcon />
        </div>
      }
      <div className="info-container">
        <div className="title">Create Order</div>
        <div className="products-form">
          <Row gutter={[15, 0]}>
            <Col xs={24} sm={12}>
              <div className="select-user-section input-form">
                <label>Select User*</label>
                <br />
                <UserSearchForm
                  setSelectedUserInfo={setSelectedUserInfo}
                  size="large"
                />
                <span className="error">{errors.user_error && errors.user_error}</span>
              </div>
            </Col>
          </Row>
          <Row gutter={[15, 0]}>
            <Col xs={24} sm={12}>
              <div className="products input-form">
                <label>Products*</label>
                <br />
                <Select
                  value={selectedProduct}
                  onChange={(value) => setSelectedProduct(value)}
                  size="large"
                  showSearch
                  options={[
                    {
                      value: "",
                      key: "",
                      label: "Select Product"
                    },
                    ...products.map((el) => ({
                      value: el.id,
                      key: el.id,
                      label: el.title
                    }))
                  ]}
                  optionFilterProp="label"
                />
                <span className="error">{errors.product_error && errors.product_error}</span>
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <div className="add-to-cart-section">
                <InputNumber
                  value={quantity}
                  addonAfter={<span className="plus-icon" onClick={() => handleChangeQuantity("plus")}>+</span>}
                  addonBefore={<span className="minus-icon" onClick={() => handleChangeQuantity("minus")}>-</span>}
                  className="quantity"
                  controls={false}
                />
                <Button
                  onClick={handleAddToCart}
                  className="add-cart-btn"
                >
                  Add To Cart
                </Button>
              </div>
            </Col>
          </Row>
        </div>
        <Divider />
        <div className="title">Shipping Address</div>
        <div className="user-form">
          <Row gutter={[15, 0]}>
            <Col xs={24} sm={12}>
              <div className="input-form">
                <label>Address Line 1*</label>
                <br/>
                <Input
                  type='text'
                  value={shippingInfo.shipping_address} 
                  onChange={(e )=> onChangeShippingInfo('shipping_address', e.target.value)}
                  disabled={isUpdated}
                />
                <span className="error">{errors.shipping_address_error && errors.shipping_address_error}</span>
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <div className="input-form">
                <label>Address Line 2</label>
                <br/>
                <Input
                  type='text'
                  value={shippingInfo.shipping_address_line2} 
                  onChange={(e )=> onChangeShippingInfo('shipping_address_line2', e.target.value)}
                  disabled={isUpdated}
                />
                <span className="error">{errors.shipping_address_line2_error && errors.shipping_address_line2_error}</span>
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <div className="input-form">
                <label>City*</label>
                <br/>
                <Input
                  type='text'
                  value={shippingInfo.shipping_city} 
                  onChange={(e) => onChangeShippingInfo('shipping_city', e.target.value)}
                  disabled={isUpdated}
                />
                <span className="error">{errors.shipping_city_error && errors.shipping_city_error}</span>
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <div className="input-form">
                <label>Zip/Postal Code*</label>
                <br/>
                <Input
                  type='text'
                  value={shippingInfo.shipping_zipcode} 
                  onChange={(e) => onChangeShippingInfo('shipping_zipcode', e.target.value)}
                  disabled={isUpdated}
                />
                <span className="error">{errors.shipping_zipcode_error && errors.shipping_zipcode_error}</span>
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <div className="input-form">
                <label>Country*</label>
                <br/>
                <Select
                  value={shippingInfo.shipping_country}
                  onChange={(value) => onChangeShippingInfo("shipping_country", value)}
                  size="large"
                  showSearch
                  options={[
                    { 
                      label: 'Select Country', 
                      value: ''
                    },
                    ...countryStates.map(el => ({
                      label: el.name,
                      value: el.code2
                    }))
                  ]}
                  optionFilterProp="label"
                  disabled
                />
                <span className="error">{errors.shipping_country_error && errors.shipping_country_error}</span>
              </div>
            </Col>
            {states.length>0?
            <Col xs={24} sm={12}>
              <div className="input-form">
                <label>State/Province*</label>
                <br/>
                <Select 
                  value={shippingInfo.shipping_state}
                  options={[
                    {
                      value: "",
                      key: "",
                      label: "Select State"
                    },
                    ...states.map((el) => ({
                      value: el.name,
                      key: el.code,
                      label: el.name
                    }))
                  ]}
                  onChange={(value) => onChangeShippingInfo('shipping_state', value)}
                  size='large'
                  showSearch
                  disabled={isUpdated}
                />
              </div>
            </Col>
            : ''}
          </Row>
        </div>
        {(myUser && !varIs('user.status', myUser.status, 'hold_temp')) ?
          <>
          {shippingInfo.id ?
            <Popconfirm
              onConfirm={handlePurchase}
              okText="Yes"
              cancelText="No"
              title="Are you sure?"
              disabled={isPurchasing || !isEnoughCW}
            >
              <Button
                className="purchase-btn"
                disabled={isPurchasing || !isEnoughCW}
                loading={isPurchasing}
              >
                Purchase
              </Button>
            </Popconfirm>
            :
            <Popconfirm
              onConfirm={handleUpdateShipping}
              okText="Yes"
              cancelText="No"
              title="Are you sure?"
              disabled={isUpdating}
            >
              <Button
                className="purchase-btn"
                disabled={isUpdating}
                loading={isUpdating}
              >
                Update
              </Button>
            </Popconfirm>
          }
          </>
        : ''}
      </div>
    </Wrapper>
  );
}
