import { varIs, varIsIn } from "common/var";
import { getSystemSetting } from "utils/systemSetting";
import DashboardIcon from 'assets/sidebar/dashboard.png';
import CheckIcon from 'assets/sidebar/check.png';
import AccountIcon from 'assets/sidebar/account.png';
import DocIcon from 'assets/sidebar/doc.png';
import UsersIcon from 'assets/sidebar/users.png';
import WebIcon from 'assets/sidebar/web.png';
import DollarIcon from 'assets/sidebar/dollar.png';
import DocAddIcon from 'assets/sidebar/doc_add.png';
import ShopIcon from 'assets/sidebar/shop.png';

export const getMenus = (myUser, isMobile = false) => {
  let menus = []
  let menu = null
  let submenus = []
  let submenu = null
  if (varIs('user.type', myUser.type, 'affiliate') &&
    varIsIn('user.status', myUser.status, [
      'active', 'inactive', 'hold_temp'
    ]) &&
    !varIs('user.taxStatus', myUser.tax_status, 'requested') &&
    !varIs('user.verificationStatus', myUser.verification_status, 'requested')
  ) {
    menu = {
      'label': 'Dashboard',
      'route': '/',
      'icon': <img src={DashboardIcon} />,
    }
    menus.push(menu)
    menu = {
      'label': 'Getting Started',
      'route': '/tool/get_started',
      'icon': <img src={DocAddIcon} />,
    }
    menus.push(menu)
    menu = {
      'label': 'Contacts',
      'route': '/contact',
      'icon': <img src={AccountIcon} />,
    }
    submenus = []
    submenu = {
      'label': 'My Contacts',
      'route': '/contact/my_contact',
    }
    submenus.push(submenu)
    submenu = {
      'label': 'Team Contacts',
      'route': '/contact/team_contact',
    }
    submenus.push(submenu)
    menu['submenus'] = submenus
    menus.push(menu)
    menu = {
      'label': 'Proposals (Solo)',
      'route': '/proposal',
      'icon': <img src={DocIcon} />,
    }
    submenus = []
    submenu = {
      'label': 'My Proposals',
      'route': '/proposal/my_proposal',
    }
    submenus.push(submenu)
    submenu = {
      'label': 'Team Proposals',
      'route': '/proposal/team_proposal',
    }
    submenus.push(submenu)
    menu['submenus'] = submenus
    menus.push(menu)
    menu = {
      'label': 'Projects (Titan)',
      'route': '/project',
      'icon': <img src={CheckIcon} />,
    }
    submenus = []
    submenu = {
      'label': 'My Projects',
      'route': '/project/my_project',
    }
    submenus.push(submenu)
    submenu = {
      'label': 'Team Projects',
      'route': '/project/team_project',
    }
    submenus.push(submenu)
    menu['submenus'] = submenus
    menus.push(menu)
    menu = {
      'label': 'Leaderboards',
      'route': '/leaderboard',
      'icon': <img src={UsersIcon} />,
    }
    submenus = []
    submenu = {
      'label': 'Previous Day Sales',
      'route': '/leaderboard/prev',
    }
    submenus.push(submenu)
    submenu = {
      'label': 'MTD Sales',
      'route': '/leaderboard/mtd',
    }
    submenus.push(submenu)
    menu['submenus'] = submenus
    menus.push(menu)
    menu = {
      'label': 'My Website',
      'route': '/my_website',
      'icon': <img src={WebIcon} />,
    }
    submenus = []
    submenu = {
      'label': 'Solar Specialist',
      'route': '/my_website/replicated',
    }
    submenus.push(submenu)
    submenu = {
      'label': 'Capture Page',
      'route': '/my_website/capture',
    }
    submenus.push(submenu)
    menu['submenus'] = submenus
    menus.push(menu)
    menu = {
      'label': 'My Team',
      'route': '/my_team',
      'icon': <img src={UsersIcon} />,
    }
    submenus = []
    submenu = {
      'label': 'Enroller Tree',
      'route': '/my_team/enroller_tree',
    }
    submenus.push(submenu)
    submenu = {
      'label': 'My Upline (2 Levels Up)',
      'route': '/my_team/upline',
    }
    submenus.push(submenu)
    menu['submenus'] = submenus
    menus.push(menu)
    menu = {
      'label': 'Earnings',
      'route': '/earning',
      'icon': <img src={DollarIcon} />,
    }
    submenus = []
    submenu = {
      'label': 'My Earnings',
      'route': '/earning/my_earning',
    }
    submenus.push(submenu)
    submenu = {
      'label': 'Team Earnings',
      'route': '/earning/team_earning',
    }
    submenus.push(submenu)
    menu['submenus'] = submenus
    menus.push(menu)
    menu = {
      'label': 'Training',
      'route': '/tool/training',
      'icon': <img src={DocAddIcon} />,
    }
    menus.push(menu)
    menu = {
      'label': 'Resources',
      'route': '/tool/resource',
      'icon': <img src={DocAddIcon} />,
    }
    menus.push(menu)
    menu = {
      'label': 'Shop Merch',
      'route': '/shop',
      'icon': <img src={ShopIcon} />,
    }
    menus.push(menu)
    menu = {
      'label': 'My Account',
      'route': '/my_account',
      'icon': <img src={AccountIcon} />,
    }
    submenus = []
    submenu = {
      'label': 'My Orders',
      'route': '/my_account/order_history',
    }
    submenus.push(submenu)
    submenu = {
      'label': 'My Subscriptions',
      'route': '/my_account/subscription',
    }
    submenus.push(submenu)
    submenu = {
      'label': 'Profile',
      'route': '/my_account/profile',
    }
    submenus.push(submenu)
    submenu = {
      'label': 'Credit Card',
      'route': '/my_account/credit_card',
    }
    submenus.push(submenu)
    submenu = {
      'label': 'Credit Wallet',
      'route': '/my_account/credit_wallet',
    }
    submenus.push(submenu)
    submenu = {
      'label': 'Payout Setting',
      'route': '/my_account/payment_option',
    }
    submenus.push(submenu)
    menu['submenus'] = submenus
    menus.push(menu)
  }
  if (varIs('user.type', myUser.type, 'affiliate') &&
    varIsIn('user.status', myUser.status, [
      'active', 'inactive', 'hold_temp'
    ]) &&
    varIs('user.taxStatus', myUser.tax_status, 'requested')
  ) {
    menu = {
      'label': 'Tax Form',
      'route': '/tax_form',
      'icon': <img src={CheckIcon} />,
    }
    menus.push(menu)
  }
  if (varIs('user.type', myUser.type, 'affiliate') &&
    varIsIn('user.status', myUser.status, [
      'active', 'inactive', 'hold_temp'
    ]) &&
    varIs('user.verificationStatus', myUser.verification_status, 'requested')
  ) {
    menu = {
      'label': 'Verification',
      'route': '/verification',
      'icon': <img src={CheckIcon} />,
    }
    menus.push(menu)
  }

  return menus
}

export const hasPageAccess = (pathname, myUser, sudoName) => {
  const menus = getMenus(myUser)
  let isAble = false
  menus.forEach(el => {
    if (el.route === pathname) {
      isAble = true
    }
    if (el.submenus && el.submenus.length > 0) {
      el.submenus.forEach(el2 => {
        if (el2.route === pathname) {
          isAble = true
        }
      })
    }
  })
  // --- external urls
  if (varIs('user.type', myUser.type, 'affiliate') &&
    varIsIn('user.status', myUser.status, [
      'active', 'inactive', 'hold_temp'
    ]) &&
    !varIs('user.taxStatus', myUser.tax_status, 'requested') &&
    !varIs('user.verificationStatus', myUser.verification_status, 'requested') &&
    [
      '/my_account/order_receipt',
      '/checkout',
    ].indexOf(pathname) >= 0
  ) {
    isAble = true
  }

  return isAble
}

export const getPageTitle = (pathname, myUser) => {
  let pageTitle = null
  const menus = getMenus(myUser)
  menus.forEach(menu0 => {
    if (menu0.route === pathname) {
      pageTitle = menu0.label
    }
    if (menu0.submenus) {
      menu0.submenus.forEach(submenu0 => {
        if (submenu0.route === pathname) {
          pageTitle = submenu0.label
        }
      })
    }
  })
  if (pathname === '/publisher') {
    pageTitle = 'Live Stream'
  }

  return pageTitle
}
