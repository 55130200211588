import React from 'react';
import styled from 'styled-components';
import { 
  Row, Col, Alert,
  Button, SuccessNotification, 
} from "components/common";
import { getUser } from "utils/auth";
import WebsiteImage from "assets/website.png";

export default function ReplicatedPage() {
  const myUser = getUser();

  const copyWebsiteLink = () => {
    SuccessNotification("Successfully copied to clipboard");
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_CORP}/${myUser?.username}`
    );
  };

  return (
    <Wrapper>
      <Row gutter={[20, 20]}>
        <Col xs={24}>
          <Alert type="info" className="replicate-alert" showIcon
            message={
              <div dangerouslySetInnerHTML={{ __html: `Kumquat Solar makes it easy for Affiliates to drive traffic and get credit for sales. Please write down and save your replicated website exactly as it is written. Sending your traffic into the wrong website could result in incorrect enrollments. For questions, please email <a style="color: #1890ff; text-decoration: none" href="mailto:support@kumquatsolar.com">support@kumquatsolar.com</a>.`}} />
            }
          />
        </Col>
        <Col xs={24} xl={9} xxl={7}>
          <div className='your-website'>
            <div className="website-details">
              <img src={WebsiteImage} className="website-image" />
              <div className="website-title">Your Website Link</div>
              <div className="website-url">
                {process.env.REACT_APP_CORP}/{myUser?.username}
              </div>
            </div>
            <div className="website-button">
              <Button onClick={copyWebsiteLink}>Copy Website Link</Button>
            </div>
          </div>
        </Col>
        <Col xs={24} xl={15} xxl={17}>
        </Col>
      </Row>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-bottom: 80px;
  .replicate-alert {
    align-items: baseline;
  }
  .your-website {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(44, 39, 56, 0.06);
    padding: 10px 10px 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .website-details {
      .website-image {
        width: 100%;
        object-fit: cover;
      }
      .website-title {
        font-size: 15px;
        line-height: 21px;
        color: #7f8fa3;
        text-align: center;
        margin-top: 30px;
      }
      .website-url {
        margin: 10px 0px;
        text-align: center;
        font-weight: normal;
        font-size: 15px;
      }
    }
    .divider {
      height: 1px;
      background: #e5e5e5;
      position: relative;
      top: -16px;
      z-index: 76;
    }
    .website-button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      button{
          width: 70%;
      }
    }
    .sharable-container {
      display: flex;
      align-items: center;
      justify-content: center;
      .sharable-links {
        padding: 0px 20px;
        position: relative;
        z-index: 88;
        background: white;
        .social-icon {
          margin: 0px 4px;
          cursor: pointer;
        }
      }
    }
    @media (max-width: 600px) {
      .website-button {
        margin-top: 30px;
        button {
          width: 100%;
        }
      }
    }
  }
`
