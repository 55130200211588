import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useSelector } from "react-redux"
import styled from 'styled-components'
import { 
  TablePanel, Select, 
  Button,
  RankBadge, UserAvatar, CountryFlag
} from 'components/common'
import { callGetApiWithAuth } from 'utils/api'
import { asDate, asVolume } from 'utils/text'
import { varIs, varKey, varLabel } from 'common/var'
import { BsChatDots } from 'react-icons/bs'
import { LoadingOutlined } from '@ant-design/icons'
import { FaFacebookMessenger, FaTelegramPlane, FaWhatsapp } from "react-icons/fa"
import UplineBar from './UplineBar'
import QualModal from './QualModal'

export default function ListTable(props) {
  const history = useHistory()
  const myUser = useSelector((state) => state.auth.user)
  const [tableData, setTableData] = useState([])
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 15,
    total: 0,
  })
  const [searchParam, setSearchParam] = useState({
    status: ''
  })
  const [isLoading, setIsLoading] = useState(false)
  const [uplines, setUplines] = useState([])
  const [isLoadingDownliner, setIsLoadingDownliner] = useState(false)
  const [clickedUser, setClickedUser] = useState([])
  const [selectedQual, setSelectedQual] = useState(false)

  const onGetTableData = (res) => {
    setTableData(res.data.childrenData.data)
    setPaginationParam({
      ...paginationParam,
      currentPage: res.data.childrenData.current_page,
      total: res.data.childrenData.total,
    })
    setUplines(res.data.uplines)
    setIsLoading(false)
  }
  const onFailTableData = () => {
    setIsLoading(false)
  }
  const loadTableData = (paginationParam0, searchParam0) => {
    setIsLoading(true)
    const params = {
      user_id: props.selectedId,
      ...searchParam0,      
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    }
    const q = Object.keys(params).map(k=>k+'='+params[k]).join('&')
    callGetApiWithAuth(`my_team/unilevel_list/table?${q}`, onGetTableData, onFailTableData)
  }
  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page
    }
    loadTableData(paginationParam0, searchParam)
  }

  const onSuccessGetDownliner = ({ data }) => {
    setIsLoadingDownliner(false)
    if (data && data.length > 0) {
      history.push({
        pathname: '/team_chat',
        state: {
          user: data, 
        },
      });
    }
  }

  const onClickChat = (user) => {
    setClickedUser(user);
    setIsLoadingDownliner(true);
    callGetApiWithAuth(`common/search_enroll_downline_affiliate/${user.username}`, onSuccessGetDownliner, () => {
      setIsLoadingDownliner(false)
    });
  }

  useEffect(() => {
    if (myUser) {
      const paginationParam0 = {
        ...paginationParam,
        currentPage: 1,
      }
      setPaginationParam(paginationParam0)
      loadTableData(paginationParam0, searchParam)
    }
  }, [myUser, props.selectedId, searchParam])

  return (
    <>
      <TablePanel
        title={
          <div className='d-flex align-items-center'>
            {`Enroller List`}
          </div>
        }
        data={tableData}
        loading={isLoading}
        paginationParam={paginationParam}
        onPageChange={onPageChange}
        toolbar={
          <UplineBar uplines={uplines} goUser={props.goUser} />
        }
        columns={[
          {
            title: 'ID',
            key: 'uuid',
            render: (_, record) => (
              <span>#{record.uuid}</span>
            )
          },
          {
            title: 'Level',
            key: 'level',
            render: (_, record) => (
              <span>{record.level}</span>
            )
          },
          {
            title: 'Username',
            key: 'username',
            render: (_, record) => (
              <div className='d-flex align-items-center'>
                {record.has_children?
                  <a href="javascript:void(0)"
                    onClick={() => props.goUser(record)}
                  >
                    {record.username}
                  </a>
                :
                  <span>{record.username}</span>
                }
              </div>
            )
          },
          {
            title: 'Name',
            key: 'name',
            render: (_, record) => (
              <div className='d-flex align-items-center'>
                <UserAvatar 
                  image={record.image}
                  title={`${record.first_name} ${record.last_name}`}
                  status={varKey('user.status', record.status)}
                  type={varKey('user.type', record.type)}
                  typeLabel={varLabel('user.type', record.type)}
                  joinDate={asDate(record.created_at)}
                />
                {(record.social_messenger && 
                  record.social_messenger.facebook_enabled*1===1 && 
                  record.social_messenger.facebook_link
                ) && 
                <span className='chat-icon'>
                  <FaFacebookMessenger 
                    onClick={()=>{
                      window.open(record.social_messenger.facebook_link, '_blank')
                    }}
                  />
                </span>
                }
                {(record.social_messenger && 
                  record.social_messenger.telegram_enabled*1===1 && 
                  record.social_messenger.telegram_link
                ) && 
                <span className='chat-icon'>
                  <FaTelegramPlane 
                    onClick={()=>{
                      window.open(record.social_messenger.telegram_link, '_blank')
                    }}
                  />
                </span>
                }
                {(record.social_messenger && 
                  record.social_messenger.whatsapp_enabled*1===1 && 
                  record.social_messenger.whatsapp_link
                ) && 
                <span className='chat-icon'>
                  <FaWhatsapp 
                    onClick={()=>{
                      window.open(record.social_messenger.whatsapp_link, '_blank')
                    }}
                  />
                </span>
                }
              </div>
              
            )
          },
          {
            title: 'Country',
            key: 'country',
            render: (_, record) => (
              <CountryFlag country={record.country} />
            )
          },
        ]}
      />
      {selectedQual && 
      <QualModal
        selectedQual={selectedQual}
        setSelectedQual={setSelectedQual}
      />
      }
    </>
  )
}

const StatusSelectWrapper = styled.div`
  width: 120px;
  .ant-select {
    width: 100%;
  }
`
