import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import moment from "moment";
import { 
  Row, Col, 
  Button, Input, Dropzone, Switch,
  Popconfirm, SuccessNotification, message, DatePicker
} from "components/common";
import { callPostApiWithAuth, callPutApiWithAuth } from 'utils/api'
import { regionByCountry } from 'common/country'
import { varIs, varValue } from "common/var";
import { UploadOutlined } from "@ant-design/icons";
import NoPhotoIcon from "assets/user.svg";
import { ReactComponent as LockIcon } from "assets/lock.svg";
import PasswordModal from "./PasswordModal";
import { Wrapper } from "./ProfilePage.styled";

export default function ProfileSubPage() {
  const dispatch = useDispatch()
  const myUser = useSelector(state=>state.auth.user)
  const [isSubscribedEmail, setIsSubscribedEmail] = useState(2)
  const [isVerified, setIsVerified] = useState(false)
  const [isFreeUser, setIsFreeUser] = useState(true)
  const [isSendingEVerif, setIsSendingEVerif] = useState(false)
  const [formData, setFormData] = useState({
    first_name: '', last_name: '',
    country: '',
    username: '', uuid: '',
    email: '', phone: '',
    image: undefined,
    imageFile: null,
    dob: moment(new Date("1990-01-01")),
    company_name: '',
    vat_number: '',
  })
  const [modalMode, setModalMode] = useState(undefined)
  const [isUpdating, setIsUpdating] = useState(false)
  const [showContactInfo, setShowContactInfo] = useState(1)
  const [isSchatEnabled, setIsSchatEnabled] = useState(varValue('user.isSchatEnabled', 'noo'))

  const onChangeFile = (files) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    fileReader.onload = () => {
      setFormData({ 
        ...formData,
        image: fileReader.result,
        imageFile: files[0]
      })
    };
  }

  const onUpdateDone = (res) => {
    SuccessNotification(res.message)
    setIsUpdating(false)
    dispatch({
      type: 'auth.RELOAD'
    })
  }

  const onUpdateFail = (res) => {
    setIsUpdating(false)
  }

  const handleUpdate = () => {
    // Validation
    if (!formData.first_name || 
      !formData.last_name || 
      !formData.username ||
      !formData.email || 
      !formData.phone ||
      !formData.dob 
    ) {
      message.error('Please input all required fields')
      return
    }
    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i 
    if (emailRegex.exec(formData.email)==null) {
      message.error('Enter valid email')
      return
    }

    const usernameRegex = /^[a-zA-Z0-9]+[a-zA-Z0-9_]+$/i
    if (usernameRegex.exec(formData.username)==null) {
      message.error('Please input username as alphanumeric')
      return
    }

    if (!formData.dob) {
      message.error('Please input date of birth')
      return
    } else if ((moment().year()*1 - moment(formData.dob).year()*1) < 18) {
      message.error("You must be 18+ years old")
      return
    }

    const body = new FormData()
    Object.keys(formData).map(k => {
      if (formData[k] && k !== "dob" && k !== "image") {
        let formValue = formData[k]
        if (k==='email') formValue = formValue.toLowerCase().trim()
        else if (k==='first_name') formValue = formValue.trim()
        else if (k==='last_name') formValue = formValue.trim()
        
        body.append(k, formValue)
      }
    })
    body.append("dob", moment(formData.dob).format("YYYY-MM-DD"))
    setIsUpdating(true)
    callPostApiWithAuth(`my_account/profile/update`, body, 
      onUpdateDone, onUpdateFail
    )
  }

  const onUpdatedShowContact = (res) => {
    setIsSendingEVerif(false)
    SuccessNotification(res.message)
    dispatch({ type: 'auth.RELOAD' })
  }
  const onChangeShowContactInfo = (checked) => {
    setShowContactInfo(checked?1:2)
    callPutApiWithAuth(`my_account/profile/show_contact_info`, 
      {show_contact_info: checked?1:2},
      onUpdatedShowContact
    )
  }

  const handleVerifyEmail = () => {
    setIsSendingEVerif(true)
    callPostApiWithAuth("my_account/profile/send_verification_email", {}, onUpdatedShowContact)
  }

  const onChangeSubscribeEmailStatus = (checked) => {
    setIsSubscribedEmail(checked?1:2)
    callPutApiWithAuth(`my_account/profile/subscribe_email`, 
      {is_subscribed_email: checked?1:2},
      onUpdatedShowContact
    )
  }

  const onChangeSchatEnabled = (checked) => {
    setIsSchatEnabled(checked?1:2)
    callPutApiWithAuth(`my_account/profile/set_schat_enabled`, 
      {is_schat_enabled: checked?1:2},
      onUpdatedShowContact
    )
  }

  useEffect(() => {
    if (!myUser) return
    setFormData({ 
      ...formData,
      first_name: myUser.first_name,
      last_name: myUser.last_name,
      username: myUser.username,
      country: myUser.country,
      uuid: myUser.uuid,
      email: myUser.email,
      phone: myUser.phone,
      image: myUser.image,
      dob: myUser.dob ? moment(new Date(myUser.dob + "T00:00:00")) : formData.dob,
      company_name: myUser.company_name ? myUser.company_name.trim() : "",
      vat_number: myUser.vat_number,
    })
    setShowContactInfo(myUser.show_contact_info)
    setIsSubscribedEmail(myUser.is_subscribed_email)
    setIsVerified((myUser.email_verified_at && myUser.email_verified_at.length > 0) ? true : false)
    setIsFreeUser(myUser.is_free*1 === 1 ? true : false)
    setIsSchatEnabled(myUser.is_schat_enabled)
  }, [myUser])

  return (
    <Wrapper>
      <div className="image-upload-section">
        <img className='photo-img'
          src={formData.image || NoPhotoIcon} 
        />
        <div className="user-upload">
          <Dropzone
            onDrop={onChangeFile}
            accept='image/*'
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Button icon={<UploadOutlined />}>Upload Image</Button>
              </div>
            )}
          </Dropzone>
        </div>
      </div>
      <Row gutter={[30, 20]} className="settings-row">
        <Col xs={24} md={12} xl={8} xxl={6}>
          <div className="select-container">
            <div className="label">First name*</div>
            <div className="select-box">
              <Input value={formData.first_name} 
                onChange={e=>setFormData({...formData, first_name:e.target.value})}
              />
            </div>
          </div>
        </Col>
        <Col xs={24} md={12} xl={8} xxl={6}>
          <div className="select-container">
            <div className="label">Last name*</div>
            <div className="select-box">
              <Input value={formData.last_name} 
                onChange={e=>setFormData({...formData, last_name:e.target.value})}
              />
            </div>
          </div>
        </Col>
      </Row>
      {regionByCountry(formData.country) === 'Europe'?
      <Row gutter={[30, 20]} className="settings-row">
        <Col xs={24} md={12} xl={8} xxl={6}>
          <div className="select-container">
            <div className="label">VAT Number (Optional)</div>
            <div className="select-box">
              <Input value={formData.vat_number} 
                onChange={e=>setFormData({...formData, vat_number:e.target.value})}
              />
            </div>
          </div>
        </Col>
      </Row>
      : ''}
      <Row gutter={[20, 20]} className="settings-row">
        <Col xs={24} md={24} xl={16} xxl={12}>
          <div className="username-container">
            <div className="username">Username:</div>
            <div className="username-input">
              <Input value={formData.username}
                onChange={e=>setFormData({...formData, username:e.target.value})}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={[20, 20]} className="settings-row">
        <Col xs={24} md={24} xl={16} xxl={12}>
          <div className="username-container">
            <div className="username">Account ID:</div>
            <div className="username-input">
              <Input disabled value={`#${formData.uuid}`} suffix={<LockIcon />} />
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={[20, 20]} className="settings-row">
        <Col xs={24} md={24} xl={16} xxl={12}>
          <div className="username-container">
            <div className="username">Email*:</div>
            <div className="username-input">
              <Input value={formData.email} 
                onChange={e=>setFormData({...formData, email:e.target.value})}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={[20, 20]} className="settings-row">
        <Col xs={24} md={24} xl={16} xxl={12}>
          <div className="username-container">
            <div className="username">Phone*:</div>
            <div className="username-input">
              <Input value={formData.phone} 
                onChange={e=>setFormData({...formData, phone:e.target.value})}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={[20, 20]} className="settings-row">
        <Col xs={24} md={24} xl={16} xxl={12}>
          <div className="username-container">
            <div className="username">Date of Birth*:</div>
            <div className="username-input">
              <DatePicker
                value={formData.dob} 
                onChange={(value) => setFormData({ ...formData, dob: value })}
                size="small"
                className="date-picker-dob"
              />
            </div>
          </div>
        </Col>
      </Row>
      {/* <Row gutter={[20, 20]} className="settings-row">
        <Col xs={24} md={24} xl={16} xxl={12}>
          <div className="username-container">
            <div className="username">Ownership Name*:</div>
            <div className="username-input">
              <Input value={formData.company_name} suffix={<LockIcon />}
                onChange={e=>setFormData({...formData, company_name:e.target.value})}
                disabled={myUser && myUser.company_name}
              />
            </div>
          </div>
        </Col>
      </Row> */}
      <Row gutter={[20, 20]} className="settings-row">
        <Col xs={24} md={24} xl={16} xxl={12}>
          <div className="select-container">
            <div className="label">Contact Info Visibility</div>
            <div className="select-box">
              Show my contact info for the back office and corporate replicated website:&nbsp;
              <Switch checked={showContactInfo===1} 
                onChange={onChangeShowContactInfo}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={[20, 20]} className="settings-row">
        <Col xs={24} md={24} xl={16} xxl={12}>
          <div className="select-container">
            <div className="label">Subscribe Emails</div>
            <div className="select-box">
              If you switch off then you will not receive any emails from kumquat Solar.&nbsp;
              <Switch
                checked={isSubscribedEmail===1} 
                onChange={onChangeSubscribeEmailStatus}
                className="contact-info-visibility-switch"
                disabled={(isFreeUser && !isVerified) ? true : false}
              />
            </div>
          </div>
        </Col>
      </Row>
      {(isFreeUser && !isVerified) &&
        <Row gutter={[20, 20]} className="settings-row">
          <Col xs={24} md={24} xl={16} xxl={12}>
            <div className="select-container">
              <div className="label">Email Verification</div>
              <div className="select-box">
                You will receive a verification email.
                <br />
                <Button loading={isSendingEVerif} onClick={handleVerifyEmail} className="email-verify-btn">
                  Verify Email
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      }
      
      {/* <Row gutter={[20, 20]} className="settings-row">
        <Col xs={24} md={24} xl={16} xxl={12}>
          <div className="select-container">
            <div className="label">Prospect Chat</div>
            <div className="select-box">
              Enable live chat with my prospects on corporate website
              <Switch
                checked={varIs('user.isSchatEnabled', isSchatEnabled, 'yes')} 
                onChange={onChangeSchatEnabled}
                className="contact-info-visibility-switch"
              />
            </div>
          </div>
        </Col>
      </Row> */}
      
      <div className="save-button">
        <Popconfirm onConfirm={handleUpdate}>
          <Button loading={isUpdating}>
            Save
          </Button>
        </Popconfirm>
      </div>
      <div className="change-button">
        <Button onClick={()=>setModalMode('password')}>
          Change Password
        </Button>
      </div>
      {modalMode==='password' && 
      <PasswordModal 
        setModalMode={setModalMode}
      />
      }
    </Wrapper>
  );
}
