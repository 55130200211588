import React from 'react';
import styled from 'styled-components';

export default function TeamEarningPage() {
  return (
    <Wrapper>
      <div className='coming-root'>
        Coming Soon...
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .coming-root {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 450px;
    font-size: 28px;
    color: #909090;
    background: #fff;
    box-shadow: 0px 4px 20px rgb(44 39 56 / 6%);
  }
`
