import React, { useEffect, useState } from 'react';
import {
  Input, Button,
} from 'components/common'

export default function CcForm(props) {
  const [formData, setFormData] = useState(props.billingDetail)
  const [errorMessages, setErrorMessages] = useState([])

  const onFormInput = (field, value) => {
    setFormData({
      ...formData,
      [field]: value
    })
  }

  const handleContinue = () => {
    let errorMessages0 = [];
    [
      'cc_name', 'cc_number',
      'cc_exp_date', 'cc_cvv'
    ].forEach(k => {
      if (!formData[k]) {
        errorMessages0.push({
          field: k,
          message: 'Required'
        })
      }
    })
    const regex = /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/;
    const cvvRegex = /^[0-9]{3,4}$/i;
    if (regex.exec(formData.cc_exp_date) == null) {
      errorMessages0.push({
        field: 'cc_exp_date',
        message: 'Please input as MM/YY'
      })
    }
    if (cvvRegex.exec(formData.cc_cvv) == null) {
      errorMessages0.push({
        field: 'cc_cvv',
        message: 'Please input correct cvv'
      })
    }
    setErrorMessages(errorMessages0)
    if (errorMessages0.length>0) return;

    props.setBillingDetail({
      ...props.billingDetail,
      ...formData,
      cc_number: formData.cc_number.replace(/-/g, '').replace(/\s/g, ''),
    })
    props.setStep('term')
  }

  useEffect(() => {
    if (props.billingDetail) {
      setFormData({
        ...formData,
        ...props.billingDetail,
      })
    }
  }, [props.billingDetail])

  return (
    <>
      <div className='form-card'>
        <h4>Please add your payment information for Business Fees ($19.95 per mo)</h4>
        <p>
          This monthly fee includes your personal email address, and your Web Suite (personalized website, back office, detailed genealogy, CRM, email drip campaign, sales funnel, leader board, extensive training modules and much more!) 
        </p>
        <p>
          It's like having a personal assistant for less than $20 a month. Cancel at any time.
        </p>
        <div className='form-group'>
          <Input value={formData.cc_name}
            onChange={e=>onFormInput('cc_name', e.target.value)}
            placeholder={'Name on Credit Card'}
          />
          {errorMessages.filter(el=>el.field==='cc_name')
          .map(el => (
            <div className='form-error'>
              {el.message}
            </div>
          ))}
        </div>
        <div className='form-group'>
          <Input value={formData.cc_number}
            onChange={e=>onFormInput('cc_number', e.target.value)}
            placeholder={'Card Number'}
          />
          {errorMessages.filter(el=>el.field==='cc_number')
          .map(el => (
            <div className='form-error'>
              {el.message}
            </div>
          ))}
        </div>
        <div className='form-group'>
          <Input value={formData.cc_exp_date}
            onChange={e=>onFormInput('cc_exp_date', e.target.value)}
            placeholder={'Expiration (MM/YY)'}
          />
          {errorMessages.filter(el=>el.field==='cc_exp_date')
          .map(el => (
            <div className='form-error'>
              {el.message}
            </div>
          ))}
        </div>
        <div className='form-group'>
          <Input value={formData.cc_cvv}
            onChange={e=>onFormInput('cc_cvv', e.target.value)}
            placeholder={'Security Code'}
          />
          {errorMessages.filter(el=>el.field==='cc_cvv')
          .map(el => (
            <div className='form-error'>
              {el.message}
            </div>
          ))}
        </div>
        <div className='action-group'>
          <div>
            <Button onClick={()=>props.setStep('gift')}>
              Previous
            </Button>
          </div>
          <div>
            <Button onClick={handleContinue}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
