import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { 
  Select, Button, Popconfirm, Alert,
  message, SuccessNotification 
} from "components/common";
import { varIs, varValue } from 'common/var'
import { 
  callGetApiWithAuth, 
  callPutApiWithAuth 
} from 'utils/api'
import AddModal from "../../add/AddModal";

export default function SettingSection(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const myUser = useSelector(state=>state.auth.user)
  const [payType, setPayType] = useState('')
  const [payTypeOptions, setPayTypeOptions] = useState([])
  const [isUpdating, setIsUpdating] = useState(false)
  const [upcomings, setUpcomings] = useState([])
  const [faileds, setFaileds] = useState([])
  const [isOpenAddSubscriptionModal, setIsOpenSubscriptionModal] = useState(false)

  const onGetUpcomings = (res) => {
    setUpcomings(res.data)
  }

  const onGetFaileds = (res) => {
    setFaileds(res.data)
  }

  const loadUpComingSubscriptions = () => {
    callGetApiWithAuth('my_account/subscription/upcomings', 
      onGetUpcomings
    )
  }

  const loadFailedSubscriptions = () => {
    callGetApiWithAuth('my_account/subscription/faileds', 
      onGetFaileds
    )
  }

  const onUpdateDone = (res) => {
    SuccessNotification(res.message)
    setIsUpdating(false)
    dispatch({ type: 'auth.RELOAD' })
  }
  const onUpdateFail = () => {
    setIsUpdating(false)
  }
  const handleUpdate = () => {
    // Validation
    // if (!payType) {
    //   message.error('Please select payment option')
    //   return
    // }
    
    setIsUpdating(true)
    callPutApiWithAuth('my_account/subscription/subscription_setting', 
      {pay_type: payType},
      onUpdateDone, onUpdateFail
    )
  }

  const handlePay = () => {
    const upcomings0 = upcomings.filter(el => {
      return varIs('product.isSpecialDc', el.product.is_special_dc, 'no')
    })
    const subscriptionIds = upcomings0.map(el => el.id)

    if (upcomings0.length==0) {
      message.error("Please pay special subscriptions individually")
      return
    }

    dispatch({
      type: 'checkout.ADD_SUBPAY_ALL_CART',
      payload: {
        orderDetails: upcomings0,
        subscriptionIds
      }
    })
    history.push('/checkout')
  }

  const handleResume = () => {
    const faileds0 = faileds.filter(el => {
      return varIs('product.isSpecialDc', el.product.is_special_dc, 'no')
    })
    const subscriptionIds = faileds0.map(el => el.id)

    if (faileds0.length==0) {
      message.error("Please pay special subscriptions individually")
      return
    }

    dispatch({
      type: 'checkout.ADD_RESUME_ALL_CART',
      payload: {
        orderDetails: faileds0,
        subscriptionIds
      }
    })
    history.push('/checkout')
  }

  const handleOpenAddSubscription = () => {
    setIsOpenSubscriptionModal(true)
  }

  const handleCloseAddSubscriptionModal = () => {
    setIsOpenSubscriptionModal(false)
  }

  const handleSuccessCreate = () => {
    setIsOpenSubscriptionModal(false)
    loadUpComingSubscriptions()
    loadFailedSubscriptions()
    props.setShouldLoad(true)
  }

  useEffect(() => {
    if (myUser.subscription_setting) {
      setPayType(myUser.subscription_setting.pay_type)
    }
    const payTypeOptions0 = [
      {
        label: 'Credit Card',
        value: varValue('userSubscriptionSetting.payType', 'cc')
      }
    ]
    // if (myUser.country==='US') {
    //   payTypeOptions0.push({
    //     label: 'ACH',
    //     value: varValue('userSubscriptionSetting.payType', 'bank')
    //   })
    // }
    if (varIs('user.type', myUser.type, 'affiliate')) {
      payTypeOptions0.push({
        label: 'Credit Wallet + Credit Card',
        value: varValue('userSubscriptionSetting.payType', 'cc+wallet'),
      })
    }
    // payTypeOptions0.push({
    //   label: 'Gift Wallet + Credit Card',
    //   value: varValue('userSubscriptionSetting.payType', 'cc+gift_wallet'),
    // })
    // payTypeOptions0.push({
    //   value: '',
    //   label: 'Manual Payment'
    // })

    setPayTypeOptions(payTypeOptions0)
  }, [myUser])

  useEffect(() => {
    loadUpComingSubscriptions()
    loadFailedSubscriptions()
  }, [])

  return (
    <Wrapper>
      <div className='select-root'>
        <div className="payment-select-label">Preferred Payment Method</div>
        <div className="select-box">
          <Select
            value={payType}
            options={payTypeOptions}
            onChange={setPayType}
          />
        </div>
        <div className='actions'>
          <Button loading={isUpdating}
            onClick={handleUpdate}
          >
            Save
          </Button>
          {upcomings.length>0 && 
          <>
            <span style={{width: 12}} />
            <Popconfirm 
              onConfirm={handlePay}
            >
              <Button className='pay-button'>Pay All</Button>
            </Popconfirm>
          </>
          }
          {faileds.length>0 && 
          <>
            <span style={{width: 12}} />
            <Popconfirm 
              onConfirm={handleResume}
            >
              <Button className='resume-button'>Resume All</Button>
            </Popconfirm>
          </>
          }
          <span style={{width: 12}} />
          {(myUser && !varIs('user.status', myUser.status, 'hold_temp')) ?
          <Button
            onClick={handleOpenAddSubscription}
          >
            Add Subscription
          </Button>
          : ''}
        </div>
      </div>
      {varIs('userSubscriptionSetting.payType', payType, 'cc') && 
      <Alert type={'info'}
        showIcon
        style={{ alignItems: 'baseline', marginTop: 12 }}
        message={'When using this option, your order will be paid by your credit card on file.'}
      />}
      {varIs('userSubscriptionSetting.payType', payType, 'bank') && 
      <Alert type={'info'}
        showIcon
        style={{ alignItems: 'baseline', marginTop: 12 }}
        message={'When using this option, your order will be paid by your bank account on file. ACH payments take 5-7 business days to clear. '}
      />}
      {varIs('userSubscriptionSetting.payType', payType, 'cc+wallet') && 
      <Alert type={'info'}
        showIcon
        style={{ alignItems: 'baseline', marginTop: 12 }}
        message={'When using this option, we will apply your credit wallet balance first and the remaining amount will be paid by your credit card on file.'}
      />}
      {varIs('userSubscriptionSetting.payType', payType, 'cc+gift_wallet') && 
      <Alert type={'info'}
        showIcon
        style={{ alignItems: 'baseline', marginTop: 12 }}
        message={'When using this option, we will apply gift wallet balance first and the remaining amount will be paid by your credit card on file.'}
      />}
      {payType==='' && 
      <Alert type={'warning'}
        showIcon
        style={{ alignItems: 'baseline', marginTop: 12 }}
        message={'Subscriptions will not be paid automatically with this option. Our system will notify you to pay for your subscription 7 days before the next billing date.'}
      />}
      {isOpenAddSubscriptionModal &&
        <AddModal
          handleSuccessCreate={handleSuccessCreate}
          handleCloseAddSubscriptionModal={handleCloseAddSubscriptionModal}
        />
      }
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .select-root {
    display: flex;
    justify-content: end;
    align-items: center;
    flex-wrap: wrap;
  }
  .payment-select-label {
    font-weight: 500;
    font-size: 18px;
    color: #8f9dae;
    margin-right: 15px;
  }
  .ant-select {
    width: 180px;
  }
  .actions {
    display: flex;
    margin-left: 12px;
  }
  .pay-button {
    background: rgb(151, 30, 30);
    border-color: rgb(151, 30, 30);
  }
  .resume-button {
    background: #ff1e1e;
    border-color: #ff1e1e;
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;    
    .select-box {
      width: 100% !important;
    }
    .select-box > div {
      width: 100% !important;
      margin: 5px 0px 15px;
      .ant-select {
        width: 100%;
      }
    }
    .actions {
      margin-left: 0;
    }
  }
`
