import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'

export default function BaseLayout({ children }) {
  return (
    <Wrapper>
      <GlobalStyle />
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
`

const GlobalStyle = createGlobalStyle`
  a {
    color: ${props=>props.theme.palette.link};
  }
  .ant-btn-primary {
    border-color: ${props=>props.theme.palette.primary};
    background: ${props=>props.theme.palette.primary};
    &:hover, &:active, &:focus {
      background: ${props=>props.theme.palette.primary};
      border-color: ${props=>props.theme.palette.primary};
      opacity: 0.7;
    }
  }
  .ant-btn.btn-negative {
    background-color: ${props=>props.theme.palette.error};
    border-color: ${props=>props.theme.palette.error};
  }
  .ant-btn-link {
    background: transparent !important;
    border-color: transparent !important;
    box-shadow: none;
  }
  .ant-btn {
    border-radius: 4px;
    &.ant-btn-background-ghost {
      border: 1px solid ${props=>props.theme.palette.primary};
      color: ${props=>props.theme.palette.primary};
      border-radius: 4px;
      &:hover, &:active, &:focus {
        color: white;
        border-color: ${props=>props.theme.palette.primary};
        background-color: ${props=>props.theme.palette.primary} !important;
      }
      &.ant-btn-dangerous {
        color: ${props=>props.theme.palette.error};;
        border-color: ${props=>props.theme.palette.error}; !important;
        &:hover{
          color: white;
          background-color: ${props=>props.theme.palette.error} !important;
        }
      }
      &.ant-btn-success {
        color: ${props=>props.theme.palette.success};;
        border-color: ${props=>props.theme.palette.success} !important;
        &:hover {
          color: white;
          background-color: ${props=>props.theme.palette.success} !important;
        }
      }
    }
    .anticon {
      vertical-align: middle;
    }
  }
  .ant-btn-loading-icon {
    padding-right: 10px;
  }
  .ant-input {
    &.has-error {
      border-color: @error-color;
      box-shadow: none;
    }
    span {
      &.error {
        color: @error-color;
      }
    }
  }  
  .ant-select-selector{
    overflow: auto;
  }
  .ant-popover-buttons {
    .ant-btn.ant-btn-primary {
      border-color: ${props=>props.theme.palette.primary};
      &:hover, &:active, &:focus {
        background-color: ${props=>props.theme.palette.primary};
      }
    }
  }
  .ant-tabs {
    .ant-tabs-nav {
      margin-bottom: 0px;
      margin-top: 3px;
    }
    .ant-tabs-tab {
      padding: 12px 0 9px;
      .ant-tabs-tab-btn {
        color: ${props=>props.theme.palette.secondaryText};
        padding: 0 12px;
      }
      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: ${props=>props.theme.palette.primary};
        }
      }
    }
    .ant-tabs-ink-bar {
      background: ${props=>props.theme.palette.primary} !important;
    }
  }
  .ant-input:hover, .ant-input:focus, .ant-input-focused {
    border-color: ${props=>props.theme.palette.primary};
    box-shadow: 0 0 0 2px ${props=>props.theme.palette.primary}3f;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: ${props=>props.theme.palette.primary};
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: ${props=>props.theme.palette.primary};
  }
  .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: ${props=>props.theme.palette.primary};
  }
  ant-radio-checked::after {
    border-color: ${props=>props.theme.palette.primary};
  }
  .ant-radio-inner::after {
    background-color: ${props=>props.theme.palette.primary};
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: ${props=>props.theme.palette.primary};
    box-shadow: 0 0 0 2px ${props=>props.theme.palette.primary}3f;
  }
  .ant-switch-checked {
    background-color: ${props=>props.theme.palette.primary};
  }
  .ant-picker:hover, .ant-picker-focused {
    border-color: ${props=>props.theme.palette.primary};
    box-shadow: 0 0 0 2px ${props=>props.theme.palette.primary}3f;
  }
  .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child,
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-color: ${props=>props.theme.palette.primary};
    background-color: ${props=>props.theme.palette.primary};
  }
  .ant-radio-button-wrapper:hover {
    color: ${props=>props.theme.palette.primary};
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    border-color: ${props=>props.theme.palette.primary};
    background-color: ${props=>props.theme.palette.primary};
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: ${props=>props.theme.palette.primary};
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
    background-color: ${props=>props.theme.palette.primary};
  }




  .top-tabs {
    background: white;
    margin: -20px -20px 20px -20px;
    border-top: 1px solid #eff3f6;
    padding: 0px 20px 0px 20px;

    .ant-tabs-nav {
      margin-bottom: 0px;
      margin-top: 3px;
    }
  }
`
