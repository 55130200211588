import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Dropdown, Menu } from "components/common";
import { getPageTitle } from 'common/menu'
import Notifications from './notifications/Notifications';
import VerificationModal from './verification/VerificationModal'
import {
  MenuOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
} from "@ant-design/icons";
import NoPhotoImg from "assets/user.svg";
import ShoppingCartIcon from "assets/shopping-cart.svg";
import { Badge } from "antd";
import SideCart from "components/cart/SideCart";
import { varValue } from "common/var";
import { callPostApiWithAuth } from "utils/api";

export default function TopHeader({ showDrawer }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const isCollapse = useSelector((state) => state.ui.isCollapse);
  const myUser = useSelector((state) => state.auth.user);
  const sudoName = useSelector((state) => state.auth.sudoName);
  const orderDetails = useSelector((state) => state.checkout.orderDetails)
  const shippingInfo = useSelector((state) => state.checkout.shippingInfo)
  const [orderCount, setOrderCount] = useState(0)
  const [shippingPrice, setShippingPrice] = useState(undefined)
  const [tax, setTax] = useState(undefined)
  const [isCreditWallet, setIsCreditWallet] = useState(false);
  const [hasPhysicalOrder, setHasPhysicalOrder] = useState(false);
  const [route, setRoute] = useState({ //--> It can be replaced with useRef or localStorage
    to: location.pathname,
    from: location.pathname //--> previous pathname
  });

  const onClickAccountMenu = ({ key }) => {
    if (key === "logout") {
      history.push("/logout");
    }
    history.push(key);
  };

	const handleShowCart = () => {
    if (location.pathname && location.pathname === "/checkout") {
      dispatch({
        type: "ui.HIDE_SIDE_CART"
      })
    } else {
      dispatch({
        type: "ui.SHOW_SIDE_CART"
      })
    }
	}

  const onSuccessGetShippingAndTax = ({ data }) => {
    setShippingPrice(data.shipping_price);
    setTax(data.tax_cost)
  }

  const onFailedShippingAndTax = (err) => {
    setShippingPrice(undefined)
    setTax(undefined)
  }

  const loadShippingInfo = () => {
    const data = {
      country: shippingInfo.shipping_country,
      state: shippingInfo.shipping_state,
      shippingDetail: shippingInfo,
      orderDetails: orderDetails.map((el) => ({
        ...el,
        product_id: el.product.id
      }))
    }
    callPostApiWithAuth("create_member/get_shipping_price", data, onSuccessGetShippingAndTax, onFailedShippingAndTax)
  }

  useEffect(() => {
    let orderCount_ = 0;
    for (let order of orderDetails) {
      orderCount_ += order.quantity*1;
    }
    setOrderCount(orderCount_)
    
    if (orderDetails.length > 0 && orderDetails.filter((el) => el.product.type*1 === varValue("product.type", "physical")*1).length > 0) {
      setHasPhysicalOrder(true);
    } else if (orderDetails.length > 0 && orderDetails.filter((el) => el.product.type*1 === varValue("product.type", "physical")*1).length === 0) {
      setHasPhysicalOrder(false);
    } else if (orderDetails.length === 0) {
      setHasPhysicalOrder(false);
    }
  }, [orderDetails])

  useEffect(() => {
    if (isCreditWallet && orderDetails.length > 0 && shippingInfo && hasPhysicalOrder) {
      loadShippingInfo()
    } else {
      setShippingPrice(undefined)
      setTax(undefined)
    }
  }, [orderDetails, shippingInfo, hasPhysicalOrder, isCreditWallet])

  useEffect(() => {
    setRoute((prev)=> ({to: location.pathname, from: prev.to}) )
    if (location && location.pathname && location.pathname === "/my_account/credit_wallet") {
      setIsCreditWallet(true)
    } else {
      setIsCreditWallet(false)
      setHasPhysicalOrder(false)
      dispatch({
        type: "checkout.CLEAR_SHIPPING_INFO"
      })
    }
  }, [location])

  useEffect(() => {
    if (route && route.from && 
      (route.from === "/my_account/credit_wallet" || 
      route.from === '/checkout')
    ) {
      dispatch({
        type: "checkout.CLEAR_CART"
      })
    }
  }, [route])

  return (
    <Wrapper>
      <div className="route-title">
        <span className="mobile-side-icon">
          <MenuOutlined onClick={showDrawer} />
        </span>
        {history?.location?.pathname !== "/publisher" &&
          (isCollapse ? (
            <span className="mobile-collapse-icon">
              <DoubleRightOutlined
                onClick={() => {
                  dispatch({
                    type: "ui.SET_COLLAPSE",
                    payload: {
                      isCollapse: false,
                    },
                  });
                }}
              />
            </span>
          ) : (
            <span className="mobile-collapse-icon">
              <DoubleLeftOutlined
                onClick={() => {
                  dispatch({
                    type: "ui.SET_COLLAPSE",
                    payload: {
                      isCollapse: true,
                    },
                  });
                }}
              />
            </span>
          ))}
        {getPageTitle(history.location.pathname, myUser) 
          || history.location.pathname?.split("/")[1]?.replace("_", " ")
        }
      </div>      
      <div className="avatar-container">
        {sudoName?
          <span className='sudo-badge'>SUDO MODE</span>
        : ''}
        {/* {myUser.country ? (
          <ReactCountryFlag
            countryCode={myUser.country}
            className="react-country-flag"
            svg
            style={{
              fontSize: "26px",
              lineHeight: "0.1em",
            }}
            title={myUser.country}
          />
        ) : (
          ""
        )} */}
        <Badge count={orderCount} className="cart-badge">
          <a href="javascript:void(0)" onClick={handleShowCart}>
            <img src={ShoppingCartIcon} className="avatar-icons cart-img" />
          </a>
        </Badge>
        <Notifications />
        <div id='google_translate_element' />
        <Dropdown
          overlay={
            <Menu onClick={onClickAccountMenu}>
              {/*
              <Menu.Item key="/my_account/subscription">
                Subscriptions
              </Menu.Item>
              <Menu.Item key="/my_account/order_history">
                Order History
              </Menu.Item>
              <Menu.Item key="/my_account/credit_wallet">
                Credit Wallet
              </Menu.Item>
              <Menu.Item key="/my_account/payment_option">
                Payment Options
              </Menu.Item>
              <Menu.Item key="/my_account/setting">Account Settings</Menu.Item>
              */}
              <Menu.Item key="logout">Logout</Menu.Item>
            </Menu>
          }
        >
          <div className="avatar-box">
            <div>
              {myUser.image ? (
                <img src={myUser.image} />
              ) : (
                <img src={NoPhotoImg} />
              )}
            </div>
            <div className="user-container">
              <div className="user-title">
                {`${myUser.first_name} ${myUser.last_name}`}
              </div>
              <div className="user-id">#{myUser.uuid}</div>
            </div>
          </div>
        </Dropdown>
      </div>
      <SideCart
        shippingPrice={shippingPrice}
        tax={tax}
        isCreditWallet={isCreditWallet}
      />
      <VerificationModal />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  .route-title {
    font-size: 20px;
    color: #354052;
    text-transform: capitalize;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    display: flex;
    align-items: center;
    .anticon {
      position: relative;
      top: -4px;
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .sudo-badge {
    background-color: #f5222d;
    font-size: 14px;
    margin-right: 32px;
    height: 28px;
    line-height: 28px;
    padding: 0 6px;
    color: #fff;
  }
  .avatar-container {
    display: flex;
    align-items: center;
    .avatar-icons {
      width: 26px;
      margin-right: 35px;
      cursor: pointer;
    }
    .avatar-icons-hidden {
      width: 26px;
      display: inline;
      margin-right: 35px;
    }
    .avatar-box {
      display: flex;
      margin-right: 10px;
      align-items: center;
      cursor: pointer;
      img {
        margin-right: 8px;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        object-fit: cover;
      }
      .user-container {
        .user-title {
          height: 20px;
          font-family: "Roboto", sans-serif;
          line-height: 25px;
          color: #212529;
          font-weight: 500;
          font-size: 14px;
        }
        .user-id {
          height: 20px;
          font-family: "Roboto", sans-serif;
          line-height: 20px;
          color: #7f8fa3;
          font-weight: 400;
          font-size: 12px;
        }
      }
    }
  }
  .cart-badge {
    margin-right: 35px;
    .cart-img {
      margin-right: 0;
    }
  }
  .notification-badge {
    cursor: pointer;
    .ant-scroll-number {
      top: 2px;
      right: 38px;
      background: #09b7ef;
    }
  }
  .mobile-side-icon {
    display: none;
  }
  .mobile-collapse-icon {
    display: inline;
  }
  .react-country-flag {
    border-radius: 50%;
    margin-right: 35px;
    display: inline;
  }
  @media (max-width: 1000px) {
    .route-title {
      font-size: 19px;
    }
  }
  @media (max-width: 900px) {
    .mobile-side-icon {
      display: inline;
    }
    .mobile-collapse-icon {
      display: none;
    }
  }
  @media (max-width: 900px) {
    .react-country-flag {
      display: none !important;
    }
    .avatar-icons-hidden {
      display: none !important;
    }
  }
  @media (max-width: 575px) {
    .cart-badge {
      margin-right: 5px;
    }
    .user-container {
      display: none;
    }
    .avatar-container {
      .avatar-box {
        img {
          width: 28px;
          height: 28px;
          margin-right: 0;
        }
      }
    }
  }
`;
