import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { callPostApiWithAuth } from 'utils/api';
import StepBar from './StepBar'
import BillingForm from './BillingForm';
import ShippingForm from './ShippingForm';
import GiftForm from './GiftForm';
import CcForm from './CcForm';
import TermForm from './TermForm';
import UploadForm from './UploadForm';
import StepPointImg from 'assets/step_point.png';
import StepCurrentPointImg from 'assets/step_point_current.png';
import StepPastPointImg from 'assets/step_point_past.png';
import { SuccessNotification } from 'components/common';

export default function GetStartedPage() {
  const dispatch = useDispatch();
  const [step, setStep] = useState('billing')
  const [billingDetail, setBillingDetail] = useState({
    billing_address: '',
    billing_country: 'US',
    billing_zipcode: '',
    billing_state: '',
    billing_city: '',
    pay_type: 1, // cc
    cc_name: '',
    cc_type: 1,
    cc_number: '',
    cc_exp_date: '',
    cc_cvv: ''
  })
  const [shippingDetail, setShippingDetail] = useState({
    contact_first_name: '',
    contact_last_name: '',
    shipping_address: '',
    shipping_country: 'US',
    shipping_city: '',
    shipping_state: '',
    shipping_zipcode: '',
  })
  const [giftSetting, setGiftSetting] = useState({
    cap_size: 'Small',
    shirt_size: 'Small',
  })
  const [userData, setUserData] = useState({
    image: '',
  })
  const [isUpdating, setIsUpdating] = useState(false)

  const onSubmitDone = (res) => {
    SuccessNotification(res.message)
    setIsUpdating(false)
    dispatch({ type: "auth.RELOAD" });
  }
  const onSubmitFail = () => {
    setIsUpdating(false)
  }
  const handleSubmit = (userData0) => {
    setUserData(userData0)
    const body = {
      billingDetail,
      shippingDetail,
      giftSetting,
      user: userData0,
    }
    setIsUpdating(true)
    callPostApiWithAuth(`get_started/update`, body,
      onSubmitDone, onSubmitFail
    )
  }

  return (
    <Wrapper>
      <StepBar step={step} />
      <div className='started-content'>
        {step==='billing' && 
          <BillingForm billingDetail={billingDetail}
            setBillingDetail={setBillingDetail}
            setStep={setStep}
          />
        }
        {step==='shipping' && 
          <ShippingForm billingDetail={billingDetail}
            shippingDetail={shippingDetail}
            setShippingDetail={setShippingDetail}
            setStep={setStep}
          />
        }
        {step==='gift' && 
          <GiftForm giftSetting={giftSetting}
            setGiftSetting={setGiftSetting}
            setStep={setStep}
          />
        }
        {step==='cc' && 
          <CcForm billingDetail={billingDetail}
            setBillingDetail={setBillingDetail}
            setStep={setStep}
          />
        }
        {step==='term' && 
          <TermForm
            setStep={setStep}
          />
        }
        {step==='upload' && 
          <UploadForm setStep={setStep}
            userData={userData}
            setUserData={setUserData}
            handleSubmit={handleSubmit}
            isUpdating={isUpdating}
          />
        }
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .stepBar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 48px;
    margin-bottom: 64px;
    .stepNode {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #E4E7EC;
      position: relative;
      background: url('${StepPointImg}') no-repeat 50% 50%;
      &.past {
        background-image: url('${StepPastPointImg}');
      }
      &.current {
        background-image: url('${StepCurrentPointImg}');
      }
      .stepTitle {
        position: absolute;
        top: 38px;
        left: 0;
        margin-left: -45px;
        width: 120px;
        text-align: center;
        color: #9AAAB9;
      }
    }
    .stepLine {
      width: 100px;
      height: 1.6px;
      background-color: #E4E7EC;
    }
  }
  .started-content {
    padding-top: 70px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      font-weight: 700;
      font-size: 36px;
      line-height: 38px;
      margin-bottom: 20px;
    }
    p {
      text-align: center;
      font-size: 16px;
      line-height: 24px;
      color: #9AAAB9;
    }
    .form-card {
      background: #FFFFFF;
      border: 1px solid #EDEEF2;
      border-radius: 8px;
      width: 512px;
      padding: 40px;
      margin-top: 20px;
    }
    h4 {
      font-size: 20px;
      font-weight: 700;
      line-height: 38px;
      text-align: center;
      color: #0C1B2F;
      .yellow {
        color: #FC7F27;
      }
    }
    .form-group {
      margin: 12px 0;
    }
    .form-error {
      font-size: 0.8em;
      color: ${props=>props.theme.palette.error};
    }
    .ant-input {
      border: 1px solid #EDEEF2;
      border-radius: 8px;
      padding: 8px 12px;
    }
    .ant-select-selector {
      border: 1px solid #EDEEF2;
      border-radius: 8px;
      padding: 4px 12px;
      height: 38px;
    }
    .ant-checkbox-wrapper {
      span {
        color: #9AAAB9;
      }
    }
    .ant-select {
      width: 100%;
    }
    .file-select {
      width: 100%;
      height: 245px;
      border: 1px dashed rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #9AAAB9;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .action-group {
      display: flex;
      justify-content: space-between;
      .ant-btn {
        width: 148px;
        height: 40px;
        border: 0 none;
        background: #FC7F27;
        border-radius: 8px;
      }
    }
  }
`
