import React, { useEffect, useState } from 'react';
import {
  Select, Input, Button,
} from 'components/common';
import { countryStates, statesByCountry } from 'common/country';

export default function BillingForm(props) {
  const [formData, setFormData] = useState(props.billingDetail)
  const [errorMessages, setErrorMessages] = useState([])

  const onFormInput = (field, value) => {
    setFormData({
      ...formData,
      [field]: value
    })
  }

  const handleContinue = () => {
    let errorMessages0 = [];
    [
      'billing_address', 'billing_zipcode',
      'billing_state', 'billing_city', 'billing_country'
    ].forEach(k => {
      if (!formData[k]) {
        errorMessages0.push({
          field: k,
          message: 'Required'
        })
      }
    })
    setErrorMessages(errorMessages0)
    if (errorMessages0.length>0) return;

    props.setBillingDetail({
      ...props.billingDetail,
      ...formData,
    })
    props.setStep('shipping')
  }

  useEffect(() => {
    if (props.billingDetail) {
      setFormData({
        ...formData,
        ...props.billingDetail,
      })
    }
  }, [props.billingDetail])

  return (
    <>
      <h2>Getting Started</h2>
      <p>
        Congratulations on selecting Kumquat Solar. Please take a minute to complete your account <br/>setup and gain full access to your Solar Specialist Back-office
      </p>
      <div className='form-card'>
        <h4>Create your Billing Profile</h4>
        <div className='form-group'>
          <Input value={formData.billing_address}
            onChange={e=>onFormInput('billing_address', e.target.value)}
            placeholder={'Lot, Street and Apt #'}
          />
          {errorMessages.filter(el=>el.field==='billing_address')
          .map(el => (
            <div className='form-error'>
              {el.message}
            </div>
          ))}
        </div>
        <div className='form-group'>
          <Input value={formData.billing_city}
            onChange={e=>onFormInput('billing_city', e.target.value)}
            placeholder={'City'}
          />
          {errorMessages.filter(el=>el.field==='billing_city')
          .map(el => (
            <div className='form-error'>
              {el.message}
            </div>
          ))}
        </div>
        <div className='form-group'>
          <Select value={formData.billing_country}
            onChange={v=>onFormInput('billing_country', v)}
            options={[
              { value: '', label: 'Country' },
              ...countryStates.map(el => ({
                value: el.code2, label: el.name
              }))
            ]}
          />
          {errorMessages.filter(el=>el.field==='billing_country')
          .map(el => (
            <div className='form-error'>
              {el.message}
            </div>
          ))}
        </div>
        <div className='form-group'>
          <Select value={formData.billing_state}
            onChange={v=>onFormInput('billing_state', v)}
            options={[
              { value: '', label: 'State' },
              ...statesByCountry(formData.billing_country).map(el => ({
                value: el.name, label: el.name,
              }))
            ]}
          />
          {errorMessages.filter(el=>el.field==='billing_state')
          .map(el => (
            <div className='form-error'>
              {el.message}
            </div>
          ))}
        </div>
        <div className='form-group'>
          <Input value={formData.billing_zipcode}
            onChange={e=>onFormInput('billing_zipcode', e.target.value)}
            placeholder={'Postal Code'}
          />
          {errorMessages.filter(el=>el.field==='billing_zipcode')
          .map(el => (
            <div className='form-error'>
              {el.message}
            </div>
          ))}
        </div>
        <div className='action-group'>
          <div>&nbsp;</div>
          <div>
            <Button onClick={handleContinue}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
