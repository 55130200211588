import React from 'react'
import StatCard from './StatCard'
import TableView from './TableView'

export default function ListSubPage() {
  return (
    <>
      <StatCard />
      <div style={{height: 24}} />
      <TableView />
    </>
  )
}
