import React, { useEffect, useState } from 'react';
import {
  Checkbox, Button,
} from 'components/common'

export default function TermForm(props) {
  const [isAgreed, setIsAgreed] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])

  const handleContinue = () => {
    let errorMessages0 = [];
    if (!isAgreed) {
      errorMessages0.push({
        field: 'isAgreed',
        message: 'Please check agreement'
      })
    }
    setErrorMessages(errorMessages0)
    if (errorMessages0.length>0) return;

    props.setStep('upload')
  }

  return (
    <>
      <div className='form-card'>
        <h4><a href="https://solar-client-assets.s3.us-east-2.amazonaws.com/asset/Kumquat+Solar+Terms+%26+Conditions+(060223).pdf" target="_blank"><span className='yellow'>Click here</span></a> to download Kumquat Solar Terms and Conditions</h4>
        <div className='form-group'>
          <Checkbox checked={isAgreed}
            onChange={e=>setIsAgreed(e.target.checked)}
            label={'I have read and agree to Kumqut Solar terms and conditions.'}
          />
          {errorMessages.filter(el=>el.field==='isAgreed')
          .map(el => (
            <div className='form-error'>
              {el.message}
            </div>
          ))}
        </div>
        <div className='action-group'>
          <div>
            <Button onClick={()=>props.setStep('cc')}>
              Previous
            </Button>
          </div>
          <div>
            <Button onClick={handleContinue}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
